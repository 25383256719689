@import url(vendor/normalize.css);
:root {
  --blue: #23419f;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #C32D3A;
  --orange: #fd7e14;
  --yellow: #D69600;
  --green: #178253;
  --teal: #006DAA;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #686B73;
  --gray-dark: #343a40;
  --dark-gray: #2D323E;
  --primary: #23419f;
  --secondary: #6c757d;
  --success: #178253;
  --info: #006DAA;
  --warning: #D69600;
  --danger: #C32D3A;
  --light: #eee;
  --dark: #2D323E;
  --gray: #686B73;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #171C29;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #23419f;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #152760;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #686B73;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.25; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.25; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 700;
  line-height: 1.25; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.25; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.25; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 87.5%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fff243; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 87.5%;
  color: #686B73; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #b9babe;
  border-radius: 0.3rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #686B73; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #2D323E;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #171C29; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #171C29; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #b9babe; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #b9babe; }
  .table tbody + tbody {
    border-top: 2px solid #b9babe; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #b9babe; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #b9babe; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #171C29;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1cae4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8d9ccd; }

.table-hover .table-primary:hover {
  background-color: #afbbdc; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #afbbdc; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bedccf; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86bea6; }

.table-hover .table-success:hover {
  background-color: #add3c3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #add3c3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8d6e7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ab3d3; }

.table-hover .table-info:hover {
  background-color: #a5cbe1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a5cbe1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f4e2b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #eac87a; }

.table-hover .table-warning:hover {
  background-color: #f1d9a2; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f1d9a2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #eec4c8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e09299; }

.table-hover .table-danger:hover {
  background-color: #e8b0b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e8b0b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafafa; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f6f6f6; }

.table-hover .table-light:hover {
  background-color: #ededed; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ededed; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c6c9; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #92949b; }

.table-hover .table-dark:hover {
  background-color: #b7b9bd; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7b9bd; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #d5d6d8; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #b0b2b6; }

.table-hover .table-gray:hover {
  background-color: #c8c9cc; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #c8c9cc; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #2D323E;
  border-color: #3d4454; }

.table .thead-light th {
  color: #2D323E;
  background-color: #F9F9F9;
  border-color: #b9babe; }

.table-dark {
  color: #fff;
  background-color: #2D323E; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #3d4454; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2D323E;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b9babe;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2D323E; }
  .form-control:focus {
    color: #2D323E;
    background-color: #fff;
    border-color: #6582dd;
    outline: 0;
    box-shadow: 0 0 0 1px #6c83cd; }
  .form-control::placeholder {
    color: #686B73;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F9F9F9;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #2D323E;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #171C29;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.35rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #686B73; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #30A171; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(48, 161, 113, 0.9);
  border-radius: 0.3rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #30A171; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #30A171;
    box-shadow: 0 0 0 1px rgba(48, 161, 113, 0.25); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #30A171; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #30A171;
    box-shadow: 0 0 0 1px rgba(48, 161, 113, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #30A171; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #30A171; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #30A171; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3ec68c;
  background-color: #3ec68c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(48, 161, 113, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #30A171; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #30A171; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #30A171;
  box-shadow: 0 0 0 1px rgba(48, 161, 113, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #E4406E; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(228, 64, 110, 0.9);
  border-radius: 0.3rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E4406E; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E4406E;
    box-shadow: 0 0 0 1px rgba(228, 64, 110, 0.25); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E4406E; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E4406E;
    box-shadow: 0 0 0 1px rgba(228, 64, 110, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E4406E; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E4406E; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E4406E; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea6d90;
  background-color: #ea6d90; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(228, 64, 110, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E4406E; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E4406E; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E4406E;
  box-shadow: 0 0 0 1px rgba(228, 64, 110, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #171C29;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #171C29;
    text-decoration: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #23419f;
  border-color: #23419f; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1a337b;
    border-color: #1a337b; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #23419f;
    border-color: #23419f; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #03195c;
    border-color: #03195c; }
  .btn-primary.focus, .btn-primary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-primary.focus:before, .btn-primary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #6c83cd;
      border-radius: calc(50rem + 4px); }
    .btn-primary.focus.btn-lg:before, .btn-group-lg > .btn-primary.focus.btn:before, .btn-primary:focus.btn-lg:before, .btn-group-lg > .btn-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-primary.focus.btn-sm:before, .btn-group-sm > .btn-primary.focus.btn:before, .btn-primary:focus.btn-sm:before, .btn-group-sm > .btn-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-primary.focus:active:before, .btn-primary.focus.active:before, .btn-primary:focus:active:before, .btn-primary:focus.active:before {
      opacity: .75; }
    .btn-primary.focus:disabled:before, .btn-primary.focus.disabled:before, .btn-primary:focus:disabled:before, .btn-primary:focus.disabled:before {
      display: none; }

.btn-outline-primary {
  color: #23419f;
  border-color: #23419f; }
  .btn-outline-primary:hover {
    color: #1a337b;
    background-color: #f4f7ff;
    border-color: #03195c; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #23419f;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #2D323E;
    background-color: #f4f7ff;
    border-color: #03195c; }
  .btn-outline-primary.focus, .btn-outline-primary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-primary.focus:before, .btn-outline-primary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #6c83cd;
      border-radius: calc(50rem + 4px); }
    .btn-outline-primary.focus.btn-lg:before, .btn-group-lg > .btn-outline-primary.focus.btn:before, .btn-outline-primary:focus.btn-lg:before, .btn-group-lg > .btn-outline-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-primary.focus.btn-sm:before, .btn-group-sm > .btn-outline-primary.focus.btn:before, .btn-outline-primary:focus.btn-sm:before, .btn-group-sm > .btn-outline-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-primary.focus:active:before, .btn-outline-primary.focus.active:before, .btn-outline-primary:focus:active:before, .btn-outline-primary:focus.active:before {
      opacity: .75; }
    .btn-outline-primary.focus:disabled:before, .btn-outline-primary.focus.disabled:before, .btn-outline-primary:focus:disabled:before, .btn-outline-primary:focus.disabled:before {
      display: none; }

.btn-inverse-primary {
  color: #23419f;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-primary:hover {
    color: #1c3480;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-primary.disabled, .btn-inverse-primary:disabled {
    color: #23419f;
    background-color: transparent; }
  .btn-inverse-primary:not(:disabled):not(.disabled):active, .btn-inverse-primary:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-primary.dropdown-toggle {
    color: #1a3075;
    background: #eee; }
  .btn-inverse-primary.focus, .btn-inverse-primary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-primary.focus:before, .btn-inverse-primary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-primary.focus.btn-lg:before, .btn-group-lg > .btn-inverse-primary.focus.btn:before, .btn-inverse-primary:focus.btn-lg:before, .btn-group-lg > .btn-inverse-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-primary.focus.btn-sm:before, .btn-group-sm > .btn-inverse-primary.focus.btn:before, .btn-inverse-primary:focus.btn-sm:before, .btn-group-sm > .btn-inverse-primary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-primary.focus:active:before, .btn-inverse-primary.focus.active:before, .btn-inverse-primary:focus:active:before, .btn-inverse-primary:focus.active:before {
      opacity: .75; }
    .btn-inverse-primary.focus:disabled:before, .btn-inverse-primary.focus.disabled:before, .btn-inverse-primary:focus:disabled:before, .btn-inverse-primary:focus.disabled:before {
      display: none; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #565e64;
    border-color: #565e64; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #4c5258;
    border-color: #4c5258; }
  .btn-secondary.focus, .btn-secondary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-secondary.focus:before, .btn-secondary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #b6babe;
      border-radius: calc(50rem + 4px); }
    .btn-secondary.focus.btn-lg:before, .btn-group-lg > .btn-secondary.focus.btn:before, .btn-secondary:focus.btn-lg:before, .btn-group-lg > .btn-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-secondary.focus.btn-sm:before, .btn-group-sm > .btn-secondary.focus.btn:before, .btn-secondary:focus.btn-sm:before, .btn-group-sm > .btn-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-secondary.focus:active:before, .btn-secondary.focus.active:before, .btn-secondary:focus:active:before, .btn-secondary:focus.active:before {
      opacity: .75; }
    .btn-secondary.focus:disabled:before, .btn-secondary.focus.disabled:before, .btn-secondary:focus:disabled:before, .btn-secondary:focus.disabled:before {
      display: none; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #565e64;
    background-color: #f6f7f7;
    border-color: #4c5258; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #2D323E;
    background-color: #f6f7f7;
    border-color: #4c5258; }
  .btn-outline-secondary.focus, .btn-outline-secondary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-secondary.focus:before, .btn-outline-secondary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #b6babe;
      border-radius: calc(50rem + 4px); }
    .btn-outline-secondary.focus.btn-lg:before, .btn-group-lg > .btn-outline-secondary.focus.btn:before, .btn-outline-secondary:focus.btn-lg:before, .btn-group-lg > .btn-outline-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-secondary.focus.btn-sm:before, .btn-group-sm > .btn-outline-secondary.focus.btn:before, .btn-outline-secondary:focus.btn-sm:before, .btn-group-sm > .btn-outline-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-secondary.focus:active:before, .btn-outline-secondary.focus.active:before, .btn-outline-secondary:focus:active:before, .btn-outline-secondary:focus.active:before {
      opacity: .75; }
    .btn-outline-secondary.focus:disabled:before, .btn-outline-secondary.focus.disabled:before, .btn-outline-secondary:focus:disabled:before, .btn-outline-secondary:focus.disabled:before {
      display: none; }

.btn-inverse-secondary {
  color: #6c757d;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-secondary:hover {
    color: #5a6268;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-secondary.disabled, .btn-inverse-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-inverse-secondary:not(:disabled):not(.disabled):active, .btn-inverse-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-secondary.dropdown-toggle {
    color: #545b62;
    background: #eee; }
  .btn-inverse-secondary.focus, .btn-inverse-secondary:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-secondary.focus:before, .btn-inverse-secondary:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-secondary.focus.btn-lg:before, .btn-group-lg > .btn-inverse-secondary.focus.btn:before, .btn-inverse-secondary:focus.btn-lg:before, .btn-group-lg > .btn-inverse-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-secondary.focus.btn-sm:before, .btn-group-sm > .btn-inverse-secondary.focus.btn:before, .btn-inverse-secondary:focus.btn-sm:before, .btn-group-sm > .btn-inverse-secondary.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-secondary.focus:active:before, .btn-inverse-secondary.focus.active:before, .btn-inverse-secondary:focus:active:before, .btn-inverse-secondary:focus.active:before {
      opacity: .75; }
    .btn-inverse-secondary.focus:disabled:before, .btn-inverse-secondary.focus.disabled:before, .btn-inverse-secondary:focus:disabled:before, .btn-inverse-secondary:focus.disabled:before {
      display: none; }

.btn-success {
  color: #fff;
  background-color: #178253;
  border-color: #178253; }
  .btn-success:hover {
    color: #fff;
    background-color: #175B3C;
    border-color: #175B3C; }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #178253;
    border-color: #178253; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0F4D0F;
    border-color: #0F4D0F; }
  .btn-success.focus, .btn-success:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-success.focus:before, .btn-success:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #30A171;
      border-radius: calc(50rem + 4px); }
    .btn-success.focus.btn-lg:before, .btn-group-lg > .btn-success.focus.btn:before, .btn-success:focus.btn-lg:before, .btn-group-lg > .btn-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-success.focus.btn-sm:before, .btn-group-sm > .btn-success.focus.btn:before, .btn-success:focus.btn-sm:before, .btn-group-sm > .btn-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-success.focus:active:before, .btn-success.focus.active:before, .btn-success:focus:active:before, .btn-success:focus.active:before {
      opacity: .75; }
    .btn-success.focus:disabled:before, .btn-success.focus.disabled:before, .btn-success:focus:disabled:before, .btn-success:focus.disabled:before {
      display: none; }

.btn-outline-success {
  color: #178253;
  border-color: #178253; }
  .btn-outline-success:hover {
    color: #175B3C;
    background-color: #F2FAF7;
    border-color: #0F4D0F; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #178253;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #2D323E;
    background-color: #F2FAF7;
    border-color: #0F4D0F; }
  .btn-outline-success.focus, .btn-outline-success:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-success.focus:before, .btn-outline-success:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #30A171;
      border-radius: calc(50rem + 4px); }
    .btn-outline-success.focus.btn-lg:before, .btn-group-lg > .btn-outline-success.focus.btn:before, .btn-outline-success:focus.btn-lg:before, .btn-group-lg > .btn-outline-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-success.focus.btn-sm:before, .btn-group-sm > .btn-outline-success.focus.btn:before, .btn-outline-success:focus.btn-sm:before, .btn-group-sm > .btn-outline-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-success.focus:active:before, .btn-outline-success.focus.active:before, .btn-outline-success:focus:active:before, .btn-outline-success:focus.active:before {
      opacity: .75; }
    .btn-outline-success.focus:disabled:before, .btn-outline-success.focus.disabled:before, .btn-outline-success:focus:disabled:before, .btn-outline-success:focus.disabled:before {
      display: none; }

.btn-inverse-success {
  color: #178253;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-success:hover {
    color: #11623e;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-success.disabled, .btn-inverse-success:disabled {
    color: #178253;
    background-color: transparent; }
  .btn-inverse-success:not(:disabled):not(.disabled):active, .btn-inverse-success:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-success.dropdown-toggle {
    color: #0f5737;
    background: #eee; }
  .btn-inverse-success.focus, .btn-inverse-success:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-success.focus:before, .btn-inverse-success:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-success.focus.btn-lg:before, .btn-group-lg > .btn-inverse-success.focus.btn:before, .btn-inverse-success:focus.btn-lg:before, .btn-group-lg > .btn-inverse-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-success.focus.btn-sm:before, .btn-group-sm > .btn-inverse-success.focus.btn:before, .btn-inverse-success:focus.btn-sm:before, .btn-group-sm > .btn-inverse-success.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-success.focus:active:before, .btn-inverse-success.focus.active:before, .btn-inverse-success:focus:active:before, .btn-inverse-success:focus.active:before {
      opacity: .75; }
    .btn-inverse-success.focus:disabled:before, .btn-inverse-success.focus.disabled:before, .btn-inverse-success:focus:disabled:before, .btn-inverse-success:focus.disabled:before {
      display: none; }

.btn-info {
  color: #fff;
  background-color: #006DAA;
  border-color: #006DAA; }
  .btn-info:hover {
    color: #fff;
    background-color: #004972;
    border-color: #004972; }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #006DAA;
    border-color: #006DAA; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #002F4A;
    border-color: #002F4A; }
  .btn-info.focus, .btn-info:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-info.focus:before, .btn-info:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #1C8DBE;
      border-radius: calc(50rem + 4px); }
    .btn-info.focus.btn-lg:before, .btn-group-lg > .btn-info.focus.btn:before, .btn-info:focus.btn-lg:before, .btn-group-lg > .btn-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-info.focus.btn-sm:before, .btn-group-sm > .btn-info.focus.btn:before, .btn-info:focus.btn-sm:before, .btn-group-sm > .btn-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-info.focus:active:before, .btn-info.focus.active:before, .btn-info:focus:active:before, .btn-info:focus.active:before {
      opacity: .75; }
    .btn-info.focus:disabled:before, .btn-info.focus.disabled:before, .btn-info:focus:disabled:before, .btn-info:focus.disabled:before {
      display: none; }

.btn-outline-info {
  color: #006DAA;
  border-color: #006DAA; }
  .btn-outline-info:hover {
    color: #004972;
    background-color: #EFF8FA;
    border-color: #002F4A; }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #006DAA;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #2D323E;
    background-color: #EFF8FA;
    border-color: #002F4A; }
  .btn-outline-info.focus, .btn-outline-info:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-info.focus:before, .btn-outline-info:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #1C8DBE;
      border-radius: calc(50rem + 4px); }
    .btn-outline-info.focus.btn-lg:before, .btn-group-lg > .btn-outline-info.focus.btn:before, .btn-outline-info:focus.btn-lg:before, .btn-group-lg > .btn-outline-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-info.focus.btn-sm:before, .btn-group-sm > .btn-outline-info.focus.btn:before, .btn-outline-info:focus.btn-sm:before, .btn-group-sm > .btn-outline-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-info.focus:active:before, .btn-outline-info.focus.active:before, .btn-outline-info:focus:active:before, .btn-outline-info:focus.active:before {
      opacity: .75; }
    .btn-outline-info.focus:disabled:before, .btn-outline-info.focus.disabled:before, .btn-outline-info:focus:disabled:before, .btn-outline-info:focus.disabled:before {
      display: none; }

.btn-inverse-info {
  color: #006DAA;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-info:hover {
    color: #005484;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-info.disabled, .btn-inverse-info:disabled {
    color: #006DAA;
    background-color: transparent; }
  .btn-inverse-info:not(:disabled):not(.disabled):active, .btn-inverse-info:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-info.dropdown-toggle {
    color: #004c77;
    background: #eee; }
  .btn-inverse-info.focus, .btn-inverse-info:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-info.focus:before, .btn-inverse-info:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-info.focus.btn-lg:before, .btn-group-lg > .btn-inverse-info.focus.btn:before, .btn-inverse-info:focus.btn-lg:before, .btn-group-lg > .btn-inverse-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-info.focus.btn-sm:before, .btn-group-sm > .btn-inverse-info.focus.btn:before, .btn-inverse-info:focus.btn-sm:before, .btn-group-sm > .btn-inverse-info.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-info.focus:active:before, .btn-inverse-info.focus.active:before, .btn-inverse-info:focus:active:before, .btn-inverse-info:focus.active:before {
      opacity: .75; }
    .btn-inverse-info.focus:disabled:before, .btn-inverse-info.focus.disabled:before, .btn-inverse-info:focus:disabled:before, .btn-inverse-info:focus.disabled:before {
      display: none; }

.btn-warning {
  color: #2D323E;
  background-color: #D69600;
  border-color: #D69600; }
  .btn-warning:hover {
    color: #fff;
    background-color: #996B00;
    border-color: #996B00; }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #2D323E;
    background-color: #D69600;
    border-color: #D69600; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #7A5500;
    border-color: #7A5500; }
  .btn-warning.focus, .btn-warning:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-warning.focus:before, .btn-warning:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #FFBF18;
      border-radius: calc(50rem + 4px); }
    .btn-warning.focus.btn-lg:before, .btn-group-lg > .btn-warning.focus.btn:before, .btn-warning:focus.btn-lg:before, .btn-group-lg > .btn-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-warning.focus.btn-sm:before, .btn-group-sm > .btn-warning.focus.btn:before, .btn-warning:focus.btn-sm:before, .btn-group-sm > .btn-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-warning.focus:active:before, .btn-warning.focus.active:before, .btn-warning:focus:active:before, .btn-warning:focus.active:before {
      opacity: .75; }
    .btn-warning.focus:disabled:before, .btn-warning.focus.disabled:before, .btn-warning:focus:disabled:before, .btn-warning:focus.disabled:before {
      display: none; }

.btn-outline-warning {
  color: #D69600;
  border-color: #D69600; }
  .btn-outline-warning:hover {
    color: #996B00;
    background-color: #fffaed;
    border-color: #7A5500; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #D69600;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #2D323E;
    background-color: #fffaed;
    border-color: #7A5500; }
  .btn-outline-warning.focus, .btn-outline-warning:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-warning.focus:before, .btn-outline-warning:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #FFBF18;
      border-radius: calc(50rem + 4px); }
    .btn-outline-warning.focus.btn-lg:before, .btn-group-lg > .btn-outline-warning.focus.btn:before, .btn-outline-warning:focus.btn-lg:before, .btn-group-lg > .btn-outline-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-warning.focus.btn-sm:before, .btn-group-sm > .btn-outline-warning.focus.btn:before, .btn-outline-warning:focus.btn-sm:before, .btn-group-sm > .btn-outline-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-warning.focus:active:before, .btn-outline-warning.focus.active:before, .btn-outline-warning:focus:active:before, .btn-outline-warning:focus.active:before {
      opacity: .75; }
    .btn-outline-warning.focus:disabled:before, .btn-outline-warning.focus.disabled:before, .btn-outline-warning:focus:disabled:before, .btn-outline-warning:focus.disabled:before {
      display: none; }

.btn-inverse-warning {
  color: #D69600;
  border-color: transparent;
  background-color: #2D323E; }
  .btn-inverse-warning:hover {
    color: #b07b00;
    background-color: #1d2028;
    border-color: transparent; }
  .btn-inverse-warning.disabled, .btn-inverse-warning:disabled {
    color: #D69600;
    background-color: transparent; }
  .btn-inverse-warning:not(:disabled):not(.disabled):active, .btn-inverse-warning:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-warning.dropdown-toggle {
    color: #a37200;
    background: #eee; }
  .btn-inverse-warning.focus, .btn-inverse-warning:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-warning.focus:before, .btn-inverse-warning:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(45, 50, 62, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-warning.focus.btn-lg:before, .btn-group-lg > .btn-inverse-warning.focus.btn:before, .btn-inverse-warning:focus.btn-lg:before, .btn-group-lg > .btn-inverse-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-warning.focus.btn-sm:before, .btn-group-sm > .btn-inverse-warning.focus.btn:before, .btn-inverse-warning:focus.btn-sm:before, .btn-group-sm > .btn-inverse-warning.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-warning.focus:active:before, .btn-inverse-warning.focus.active:before, .btn-inverse-warning:focus:active:before, .btn-inverse-warning:focus.active:before {
      opacity: .75; }
    .btn-inverse-warning.focus:disabled:before, .btn-inverse-warning.focus.disabled:before, .btn-inverse-warning:focus:disabled:before, .btn-inverse-warning:focus.disabled:before {
      display: none; }

.btn-danger {
  color: #fff;
  background-color: #C32D3A;
  border-color: #C32D3A; }
  .btn-danger:hover {
    color: #fff;
    background-color: #942947;
    border-color: #942947; }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #C32D3A;
    border-color: #C32D3A; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #691B30;
    border-color: #691B30; }
  .btn-danger.focus, .btn-danger:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-danger.focus:before, .btn-danger:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #E4406E;
      border-radius: calc(50rem + 4px); }
    .btn-danger.focus.btn-lg:before, .btn-group-lg > .btn-danger.focus.btn:before, .btn-danger:focus.btn-lg:before, .btn-group-lg > .btn-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-danger.focus.btn-sm:before, .btn-group-sm > .btn-danger.focus.btn:before, .btn-danger:focus.btn-sm:before, .btn-group-sm > .btn-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-danger.focus:active:before, .btn-danger.focus.active:before, .btn-danger:focus:active:before, .btn-danger:focus.active:before {
      opacity: .75; }
    .btn-danger.focus:disabled:before, .btn-danger.focus.disabled:before, .btn-danger:focus:disabled:before, .btn-danger:focus.disabled:before {
      display: none; }

.btn-outline-danger {
  color: #C32D3A;
  border-color: #C32D3A; }
  .btn-outline-danger:hover {
    color: #942947;
    background-color: #FCF1F4;
    border-color: #691B30; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #C32D3A;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #2D323E;
    background-color: #FCF1F4;
    border-color: #691B30; }
  .btn-outline-danger.focus, .btn-outline-danger:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-danger.focus:before, .btn-outline-danger:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #E4406E;
      border-radius: calc(50rem + 4px); }
    .btn-outline-danger.focus.btn-lg:before, .btn-group-lg > .btn-outline-danger.focus.btn:before, .btn-outline-danger:focus.btn-lg:before, .btn-group-lg > .btn-outline-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-danger.focus.btn-sm:before, .btn-group-sm > .btn-outline-danger.focus.btn:before, .btn-outline-danger:focus.btn-sm:before, .btn-group-sm > .btn-outline-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-danger.focus:active:before, .btn-outline-danger.focus.active:before, .btn-outline-danger:focus:active:before, .btn-outline-danger:focus.active:before {
      opacity: .75; }
    .btn-outline-danger.focus:disabled:before, .btn-outline-danger.focus.disabled:before, .btn-outline-danger:focus:disabled:before, .btn-outline-danger:focus.disabled:before {
      display: none; }

.btn-inverse-danger {
  color: #C32D3A;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-danger:hover {
    color: #a42631;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-danger.disabled, .btn-inverse-danger:disabled {
    color: #C32D3A;
    background-color: transparent; }
  .btn-inverse-danger:not(:disabled):not(.disabled):active, .btn-inverse-danger:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-danger.dropdown-toggle {
    color: #9a232e;
    background: #eee; }
  .btn-inverse-danger.focus, .btn-inverse-danger:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-danger.focus:before, .btn-inverse-danger:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-danger.focus.btn-lg:before, .btn-group-lg > .btn-inverse-danger.focus.btn:before, .btn-inverse-danger:focus.btn-lg:before, .btn-group-lg > .btn-inverse-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-danger.focus.btn-sm:before, .btn-group-sm > .btn-inverse-danger.focus.btn:before, .btn-inverse-danger:focus.btn-sm:before, .btn-group-sm > .btn-inverse-danger.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-danger.focus:active:before, .btn-inverse-danger.focus.active:before, .btn-inverse-danger:focus:active:before, .btn-inverse-danger:focus.active:before {
      opacity: .75; }
    .btn-inverse-danger.focus:disabled:before, .btn-inverse-danger.focus.disabled:before, .btn-inverse-danger:focus:disabled:before, .btn-inverse-danger:focus.disabled:before {
      display: none; }

.btn-light {
  color: #2D323E;
  background-color: #eee;
  border-color: #eee; }
  .btn-light:hover {
    color: #2D323E;
    background-color: #bebebe;
    border-color: #bebebe; }
  .btn-light.disabled, .btn-light:disabled {
    color: #2D323E;
    background-color: #eee;
    border-color: #eee; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #2D323E;
    background-color: #a7a7a7;
    border-color: #a7a7a7; }
  .btn-light.focus, .btn-light:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-light.focus:before, .btn-light:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #f7f7f7;
      border-radius: calc(50rem + 4px); }
    .btn-light.focus.btn-lg:before, .btn-group-lg > .btn-light.focus.btn:before, .btn-light:focus.btn-lg:before, .btn-group-lg > .btn-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-light.focus.btn-sm:before, .btn-group-sm > .btn-light.focus.btn:before, .btn-light:focus.btn-sm:before, .btn-group-sm > .btn-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-light.focus:active:before, .btn-light.focus.active:before, .btn-light:focus:active:before, .btn-light:focus.active:before {
      opacity: .75; }
    .btn-light.focus:disabled:before, .btn-light.focus.disabled:before, .btn-light:focus:disabled:before, .btn-light:focus.disabled:before {
      display: none; }

.btn-outline-light {
  color: #eee;
  border-color: #eee; }
  .btn-outline-light:hover {
    color: #bebebe;
    background-color: #fefefe;
    border-color: #a7a7a7; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eee;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #2D323E;
    background-color: #fefefe;
    border-color: #a7a7a7; }
  .btn-outline-light.focus, .btn-outline-light:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-light.focus:before, .btn-outline-light:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #f7f7f7;
      border-radius: calc(50rem + 4px); }
    .btn-outline-light.focus.btn-lg:before, .btn-group-lg > .btn-outline-light.focus.btn:before, .btn-outline-light:focus.btn-lg:before, .btn-group-lg > .btn-outline-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-light.focus.btn-sm:before, .btn-group-sm > .btn-outline-light.focus.btn:before, .btn-outline-light:focus.btn-sm:before, .btn-group-sm > .btn-outline-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-light.focus:active:before, .btn-outline-light.focus.active:before, .btn-outline-light:focus:active:before, .btn-outline-light:focus.active:before {
      opacity: .75; }
    .btn-outline-light.focus:disabled:before, .btn-outline-light.focus.disabled:before, .btn-outline-light:focus:disabled:before, .btn-outline-light:focus.disabled:before {
      display: none; }

.btn-inverse-light {
  color: #eee;
  border-color: transparent;
  background-color: #2D323E; }
  .btn-inverse-light:hover {
    color: #dbdbdb;
    background-color: #1d2028;
    border-color: transparent; }
  .btn-inverse-light.disabled, .btn-inverse-light:disabled {
    color: #eee;
    background-color: transparent; }
  .btn-inverse-light:not(:disabled):not(.disabled):active, .btn-inverse-light:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-light.dropdown-toggle {
    color: #d5d5d5;
    background: #eee; }
  .btn-inverse-light.focus, .btn-inverse-light:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-light.focus:before, .btn-inverse-light:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(45, 50, 62, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-light.focus.btn-lg:before, .btn-group-lg > .btn-inverse-light.focus.btn:before, .btn-inverse-light:focus.btn-lg:before, .btn-group-lg > .btn-inverse-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-light.focus.btn-sm:before, .btn-group-sm > .btn-inverse-light.focus.btn:before, .btn-inverse-light:focus.btn-sm:before, .btn-group-sm > .btn-inverse-light.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-light.focus:active:before, .btn-inverse-light.focus.active:before, .btn-inverse-light:focus:active:before, .btn-inverse-light:focus.active:before {
      opacity: .75; }
    .btn-inverse-light.focus:disabled:before, .btn-inverse-light.focus.disabled:before, .btn-inverse-light:focus:disabled:before, .btn-inverse-light:focus.disabled:before {
      display: none; }

.btn-dark {
  color: #fff;
  background-color: #2D323E;
  border-color: #2D323E; }
  .btn-dark:hover {
    color: #fff;
    background-color: #242832;
    border-color: #242832; }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2D323E;
    border-color: #2D323E; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #20232b;
    border-color: #20232b; }
  .btn-dark.focus, .btn-dark:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-dark.focus:before, .btn-dark:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #96999f;
      border-radius: calc(50rem + 4px); }
    .btn-dark.focus.btn-lg:before, .btn-group-lg > .btn-dark.focus.btn:before, .btn-dark:focus.btn-lg:before, .btn-group-lg > .btn-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-dark.focus.btn-sm:before, .btn-group-sm > .btn-dark.focus.btn:before, .btn-dark:focus.btn-sm:before, .btn-group-sm > .btn-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-dark.focus:active:before, .btn-dark.focus.active:before, .btn-dark:focus:active:before, .btn-dark:focus.active:before {
      opacity: .75; }
    .btn-dark.focus:disabled:before, .btn-dark.focus.disabled:before, .btn-dark:focus:disabled:before, .btn-dark:focus.disabled:before {
      display: none; }

.btn-outline-dark {
  color: #2D323E;
  border-color: #2D323E; }
  .btn-outline-dark:hover {
    color: #242832;
    background-color: #f2f3f3;
    border-color: #20232b; }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2D323E;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #2D323E;
    background-color: #f2f3f3;
    border-color: #20232b; }
  .btn-outline-dark.focus, .btn-outline-dark:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-dark.focus:before, .btn-outline-dark:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #96999f;
      border-radius: calc(50rem + 4px); }
    .btn-outline-dark.focus.btn-lg:before, .btn-group-lg > .btn-outline-dark.focus.btn:before, .btn-outline-dark:focus.btn-lg:before, .btn-group-lg > .btn-outline-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-dark.focus.btn-sm:before, .btn-group-sm > .btn-outline-dark.focus.btn:before, .btn-outline-dark:focus.btn-sm:before, .btn-group-sm > .btn-outline-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-dark.focus:active:before, .btn-outline-dark.focus.active:before, .btn-outline-dark:focus:active:before, .btn-outline-dark:focus.active:before {
      opacity: .75; }
    .btn-outline-dark.focus:disabled:before, .btn-outline-dark.focus.disabled:before, .btn-outline-dark:focus:disabled:before, .btn-outline-dark:focus.disabled:before {
      display: none; }

.btn-inverse-dark {
  color: #2D323E;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-dark:hover {
    color: #1d2028;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-dark.disabled, .btn-inverse-dark:disabled {
    color: #2D323E;
    background-color: transparent; }
  .btn-inverse-dark:not(:disabled):not(.disabled):active, .btn-inverse-dark:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-dark.dropdown-toggle {
    color: #181a20;
    background: #eee; }
  .btn-inverse-dark.focus, .btn-inverse-dark:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-dark.focus:before, .btn-inverse-dark:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-dark.focus.btn-lg:before, .btn-group-lg > .btn-inverse-dark.focus.btn:before, .btn-inverse-dark:focus.btn-lg:before, .btn-group-lg > .btn-inverse-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-dark.focus.btn-sm:before, .btn-group-sm > .btn-inverse-dark.focus.btn:before, .btn-inverse-dark:focus.btn-sm:before, .btn-group-sm > .btn-inverse-dark.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-dark.focus:active:before, .btn-inverse-dark.focus.active:before, .btn-inverse-dark:focus:active:before, .btn-inverse-dark:focus.active:before {
      opacity: .75; }
    .btn-inverse-dark.focus:disabled:before, .btn-inverse-dark.focus.disabled:before, .btn-inverse-dark:focus:disabled:before, .btn-inverse-dark:focus.disabled:before {
      display: none; }

.btn-gray {
  color: #fff;
  background-color: #686B73;
  border-color: #686B73; }
  .btn-gray:hover {
    color: #fff;
    background-color: #2D323E;
    border-color: #2D323E; }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background-color: #686B73;
    border-color: #686B73; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #171C29;
    border-color: #171C29; }
  .btn-gray.focus, .btn-gray:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-gray.focus:before, .btn-gray:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #A2A4A9;
      border-radius: calc(50rem + 4px); }
    .btn-gray.focus.btn-lg:before, .btn-group-lg > .btn-gray.focus.btn:before, .btn-gray:focus.btn-lg:before, .btn-group-lg > .btn-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-gray.focus.btn-sm:before, .btn-group-sm > .btn-gray.focus.btn:before, .btn-gray:focus.btn-sm:before, .btn-group-sm > .btn-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-gray.focus:active:before, .btn-gray.focus.active:before, .btn-gray:focus:active:before, .btn-gray:focus.active:before {
      opacity: .75; }
    .btn-gray.focus:disabled:before, .btn-gray.focus.disabled:before, .btn-gray:focus:disabled:before, .btn-gray:focus.disabled:before {
      display: none; }

.btn-outline-gray {
  color: #686B73;
  border-color: #686B73; }
  .btn-outline-gray:hover {
    color: #2D323E;
    background-color: #F9F9F9;
    border-color: #171C29; }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #686B73;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #2D323E;
    background-color: #F9F9F9;
    border-color: #171C29; }
  .btn-outline-gray.focus, .btn-outline-gray:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-outline-gray.focus:before, .btn-outline-gray:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px #A2A4A9;
      border-radius: calc(50rem + 4px); }
    .btn-outline-gray.focus.btn-lg:before, .btn-group-lg > .btn-outline-gray.focus.btn:before, .btn-outline-gray:focus.btn-lg:before, .btn-group-lg > .btn-outline-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-gray.focus.btn-sm:before, .btn-group-sm > .btn-outline-gray.focus.btn:before, .btn-outline-gray:focus.btn-sm:before, .btn-group-sm > .btn-outline-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-outline-gray.focus:active:before, .btn-outline-gray.focus.active:before, .btn-outline-gray:focus:active:before, .btn-outline-gray:focus.active:before {
      opacity: .75; }
    .btn-outline-gray.focus:disabled:before, .btn-outline-gray.focus.disabled:before, .btn-outline-gray:focus:disabled:before, .btn-outline-gray:focus.disabled:before {
      display: none; }

.btn-inverse-gray {
  color: #686B73;
  border-color: transparent;
  background-color: #fff; }
  .btn-inverse-gray:hover {
    color: #56585f;
    background-color: #ececec;
    border-color: transparent; }
  .btn-inverse-gray.disabled, .btn-inverse-gray:disabled {
    color: #686B73;
    background-color: transparent; }
  .btn-inverse-gray:not(:disabled):not(.disabled):active, .btn-inverse-gray:not(:disabled):not(.disabled).active,
  .show > .btn-inverse-gray.dropdown-toggle {
    color: #505258;
    background: #eee; }
  .btn-inverse-gray.focus, .btn-inverse-gray:focus {
    position: relative;
    outline: 0;
    box-shadow: none; }
    .btn-inverse-gray.focus:before, .btn-inverse-gray:focus:before {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: solid 2px rgba(255, 255, 255, 0.8);
      border-radius: calc(50rem + 4px); }
    .btn-inverse-gray.focus.btn-lg:before, .btn-group-lg > .btn-inverse-gray.focus.btn:before, .btn-inverse-gray:focus.btn-lg:before, .btn-group-lg > .btn-inverse-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-gray.focus.btn-sm:before, .btn-group-sm > .btn-inverse-gray.focus.btn:before, .btn-inverse-gray:focus.btn-sm:before, .btn-group-sm > .btn-inverse-gray.btn:focus:before {
      border-radius: calc(50rem + 4px); }
    .btn-inverse-gray.focus:active:before, .btn-inverse-gray.focus.active:before, .btn-inverse-gray:focus:active:before, .btn-inverse-gray:focus.active:before {
      opacity: .75; }
    .btn-inverse-gray.focus:disabled:before, .btn-inverse-gray.focus.disabled:before, .btn-inverse-gray:focus:disabled:before, .btn-inverse-gray:focus.disabled:before {
      display: none; }

.btn-link {
  font-weight: 400;
  color: #23419f;
  text-decoration: none; }
  .btn-link:hover {
    color: #152760;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #686B73;
    pointer-events: none; }

.btn-light.focus, .btn-light:focus,
.btn-dark.focus,
.btn-dark:focus {
  position: relative;
  outline: 0;
  box-shadow: none; }
  .btn-light.focus:before, .btn-light:focus:before,
  .btn-dark.focus:before,
  .btn-dark:focus:before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: solid 2px #6c83cd;
    border-radius: calc(50rem + 4px); }
  .btn-light.focus.btn-lg:before, .btn-group-lg > .btn-light.focus.btn:before, .btn-light:focus.btn-lg:before, .btn-group-lg > .btn-light.btn:focus:before, .btn-dark.focus.btn-lg:before, .btn-group-lg > .btn-dark.focus.btn:before, .btn-dark:focus.btn-lg:before, .btn-group-lg > .btn-dark.btn:focus:before {
    border-radius: calc(50rem + 4px); }
  .btn-light.focus.btn-sm:before, .btn-group-sm > .btn-light.focus.btn:before, .btn-light:focus.btn-sm:before, .btn-group-sm > .btn-light.btn:focus:before, .btn-dark.focus.btn-sm:before, .btn-group-sm > .btn-dark.focus.btn:before, .btn-dark:focus.btn-sm:before, .btn-group-sm > .btn-dark.btn:focus:before {
    border-radius: calc(50rem + 4px); }
  .btn-light.focus:active:before, .btn-light.focus.active:before, .btn-light:focus:active:before, .btn-light:focus.active:before,
  .btn-dark.focus:active:before,
  .btn-dark.focus.active:before,
  .btn-dark:focus:active:before,
  .btn-dark:focus.active:before {
    opacity: .75; }
  .btn-light.focus:disabled:before, .btn-light.focus.disabled:before, .btn-light:focus:disabled:before, .btn-light:focus.disabled:before,
  .btn-dark.focus:disabled:before,
  .btn-dark.focus.disabled:before,
  .btn-dark:focus:disabled:before,
  .btn-dark:focus.disabled:before {
    display: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 50rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 50rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #171C29;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F9F9F9; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #171C29;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #0e1119;
    text-decoration: none;
    background-color: #F9F9F9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #23419f; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #686B73;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #686B73;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #171C29; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -2px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2D323E;
  text-align: center;
  white-space: nowrap;
  background-color: #F9F9F9;
  border: 1px solid #b9babe;
  border-radius: 0.3rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.35rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #23419f;
    background-color: #23419f; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #6c83cd; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6582dd; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #8ea4e6;
    border-color: #8ea4e6; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #686B73; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F9F9F9; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #b9babe solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #23419f;
  background-color: #23419f; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 65, 159, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(35, 65, 159, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 65, 159, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #b9babe;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(35, 65, 159, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2D323E;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%232D323E' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #b9babe;
  border-radius: 0.3rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #6582dd;
    outline: 0;
    box-shadow: 0 0 0 1px #6c83cd; }
    .custom-select:focus::-ms-value {
      color: #2D323E;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #686B73;
    background-color: #F9F9F9; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2D323E; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6582dd;
    box-shadow: 0 0 0 1px #6c83cd; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #F9F9F9; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2D323E;
  background-color: #fff;
  border: 1px solid #b9babe;
  border-radius: 0.3rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #2D323E;
    content: "Browse";
    background-color: #F9F9F9;
    border-left: inherit;
    border-radius: 0 0.3rem 0.3rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px #6c83cd; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px #6c83cd; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px #6c83cd; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #23419f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #8ea4e6; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #A2A4A9;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #23419f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #8ea4e6; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #A2A4A9;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 1px;
    margin-left: 1px;
    background-color: #23419f;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #8ea4e6; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #A2A4A9;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #A2A4A9;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #686B73; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #686B73; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #686B73; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #686B73;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #b9babe; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #b9babe #b9babe #b9babe; }
    .nav-tabs .nav-link.disabled {
      color: #686B73;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #2D323E;
    background-color: #fff;
    border-color: #A2A4A9 #A2A4A9 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.3rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #23419f; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card, .collapsible-card, .collapsible-card-lg {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3rem; }
  .card > hr, .collapsible-card > hr, .collapsible-card-lg > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group, .collapsible-card > .list-group, .collapsible-card-lg > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child, .collapsible-card > .list-group:first-child, .collapsible-card-lg > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px); }
    .card > .list-group:last-child, .collapsible-card > .list-group:last-child, .collapsible-card-lg > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px); }
  .card > .card-header + .list-group, .collapsible-card > .card-header + .list-group, .collapsible-card-lg > .card-header + .list-group,
  .card > .list-group + .card-footer,
  .collapsible-card > .list-group + .card-footer,
  .collapsible-card-lg > .list-group + .card-footer {
    border-top: 0; }

.card-body, .collapsible-card .collapsible-body, .collapsible-card-lg .collapsible-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.3rem - 1px) calc(0.3rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.3rem - 1px) calc(0.3rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.3rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }

.card-deck .card, .card-deck .collapsible-card, .card-deck .collapsible-card-lg {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card, .card-deck .collapsible-card, .card-deck .collapsible-card-lg {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card, .card-group > .collapsible-card, .card-group > .collapsible-card-lg {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card, .card-group > .collapsible-card, .card-group > .collapsible-card-lg {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card, .card-group > .collapsible-card + .card, .card-group > .collapsible-card-lg + .card, .card-group > .card + .collapsible-card, .card-group > .collapsible-card + .collapsible-card, .card-group > .collapsible-card-lg + .collapsible-card, .card-group > .card + .collapsible-card-lg, .card-group > .collapsible-card + .collapsible-card-lg, .card-group > .collapsible-card-lg + .collapsible-card-lg {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child), .card-group > .collapsible-card:not(:last-child), .card-group > .collapsible-card-lg:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top, .card-group > .collapsible-card:not(:last-child) .card-img-top, .card-group > .collapsible-card-lg:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header,
        .card-group > .collapsible-card:not(:last-child) .card-header,
        .card-group > .collapsible-card-lg:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .collapsible-card:not(:last-child) .card-img-bottom, .card-group > .collapsible-card-lg:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer,
        .card-group > .collapsible-card:not(:last-child) .card-footer,
        .card-group > .collapsible-card-lg:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child), .card-group > .collapsible-card:not(:first-child), .card-group > .collapsible-card-lg:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top, .card-group > .collapsible-card:not(:first-child) .card-img-top, .card-group > .collapsible-card-lg:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header,
        .card-group > .collapsible-card:not(:first-child) .card-header,
        .card-group > .collapsible-card-lg:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .collapsible-card:not(:first-child) .card-img-bottom, .card-group > .collapsible-card-lg:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer,
        .card-group > .collapsible-card:not(:first-child) .card-footer,
        .card-group > .collapsible-card-lg:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card, .card-columns .collapsible-card, .card-columns .collapsible-card-lg {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .collapsible-card, .card-columns .collapsible-card-lg {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card, .accordion > .collapsible-card, .accordion > .collapsible-card-lg {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type), .accordion > .collapsible-card:not(:last-of-type), .accordion > .collapsible-card-lg:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type), .accordion > .collapsible-card:not(:first-of-type), .accordion > .collapsible-card-lg:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header, .accordion > .collapsible-card > .card-header, .accordion > .collapsible-card-lg > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F9F9F9;
  border-radius: 0.3rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #A2A4A9;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #686B73; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #23419f;
  background-color: #fff;
  border: 1px solid #b9babe; }
  .page-link:hover {
    z-index: 2;
    color: #152760;
    text-decoration: none;
    background-color: #F9F9F9;
    border-color: #b9babe; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 1px #6c83cd; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #23419f;
  border-color: #23419f; }

.page-item.disabled .page-link {
  color: #686B73;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #F9F9F9; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #23419f; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1a3075; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(35, 65, 159, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #178253; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #0f5737; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(23, 130, 83, 0.5); }

.badge-info {
  color: #fff;
  background-color: #006DAA; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #004c77; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(0, 109, 170, 0.5); }

.badge-warning {
  color: #2D323E;
  background-color: #D69600; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #2D323E;
    background-color: #a37200; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(214, 150, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #C32D3A; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #9a232e; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(195, 45, 58, 0.5); }

.badge-light {
  color: #2D323E;
  background-color: #eee; }
  a.badge-light:hover, a.badge-light:focus {
    color: #2D323E;
    background-color: #d5d5d5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(238, 238, 238, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #2D323E; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #181a20; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(45, 50, 62, 0.5); }

.badge-gray {
  color: #fff;
  background-color: #686B73; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #fff;
    background-color: #505258; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba(104, 107, 115, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F9F9F9;
  border-radius: 0.35rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #171C29;
  background-color: #f4f7ff;
  border-color: #D8EDF8; }
  .alert-primary hr {
    border-top-color: #c2e3f4; }
  .alert-primary .alert-link {
    color: #050608; }

.alert-secondary {
  color: #171C29;
  background-color: #f6f7f7;
  border-color: #dadddf; }
  .alert-secondary hr {
    border-top-color: #ccd0d3; }
  .alert-secondary .alert-link {
    color: #050608; }

.alert-success {
  color: #171C29;
  background-color: #F2FAF7;
  border-color: #BBE6D7; }
  .alert-success hr {
    border-top-color: #a8dfcc; }
  .alert-success .alert-link {
    color: #050608; }

.alert-info {
  color: #171C29;
  background-color: #EFF8FA;
  border-color: #9CD2E6; }
  .alert-info hr {
    border-top-color: #88c9e1; }
  .alert-info .alert-link {
    color: #050608; }

.alert-warning {
  color: #171C29;
  background-color: #fffaed;
  border-color: #FFD875; }
  .alert-warning hr {
    border-top-color: #ffd15c; }
  .alert-warning .alert-link {
    color: #050608; }

.alert-danger {
  color: #171C29;
  background-color: #FCF1F4;
  border-color: #F3A9BE; }
  .alert-danger hr {
    border-top-color: #f093ad; }
  .alert-danger .alert-link {
    color: #050608; }

.alert-light {
  color: #171C29;
  background-color: #fefefe;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #050608; }

.alert-dark {
  color: #171C29;
  background-color: #f2f3f3;
  border-color: #cbcccf; }
  .alert-dark hr {
    border-top-color: #bebfc3; }
  .alert-dark .alert-link {
    color: #050608; }

.alert-gray {
  color: #171C29;
  background-color: #F9F9F9;
  border-color: #b9babe; }
  .alert-gray hr {
    border-top-color: #acadb2; }
  .alert-gray .alert-link {
    color: #050608; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #F9F9F9;
  border-radius: 0.3rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #23419f;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3rem; }

.list-group-item-action {
  width: 100%;
  color: #2D323E;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #2D323E;
    text-decoration: none;
    background-color: #F9F9F9; }
  .list-group-item-action:active {
    color: #171C29;
    background-color: #F9F9F9; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #686B73;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #23419f;
    border-color: #23419f; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.3rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.3rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.3rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.3rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.3rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.3rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.3rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.3rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #122253;
  background-color: #c1cae4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #122253;
    background-color: #afbbdc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #122253;
    border-color: #122253; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #0c442b;
  background-color: #bedccf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0c442b;
    background-color: #add3c3; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0c442b;
    border-color: #0c442b; }

.list-group-item-info {
  color: #003958;
  background-color: #b8d6e7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #003958;
    background-color: #a5cbe1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #003958;
    border-color: #003958; }

.list-group-item-warning {
  color: #6f4e00;
  background-color: #f4e2b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #6f4e00;
    background-color: #f1d9a2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #6f4e00;
    border-color: #6f4e00; }

.list-group-item-danger {
  color: #65171e;
  background-color: #eec4c8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #65171e;
    background-color: #e8b0b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #65171e;
    border-color: #65171e; }

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fafafa; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7c7c;
    background-color: #ededed; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7c7c;
    border-color: #7c7c7c; }

.list-group-item-dark {
  color: #171a20;
  background-color: #c4c6c9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #171a20;
    background-color: #b7b9bd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #171a20;
    border-color: #171a20; }

.list-group-item-gray {
  color: #36383c;
  background-color: #d5d6d8; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #36383c;
    background-color: #c8c9cc; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #36383c;
    border-color: #36383c; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #686B73;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.35rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #b9babe;
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #b9babe;
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.35rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.35rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.35rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.35rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #171C29; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #23419f !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a3075 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #178253 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0f5737 !important; }

.bg-info {
  background-color: #006DAA !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #004c77 !important; }

.bg-warning {
  background-color: #D69600 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #a37200 !important; }

.bg-danger {
  background-color: #C32D3A !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9a232e !important; }

.bg-light {
  background-color: #eee !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5d5d5 !important; }

.bg-dark {
  background-color: #2D323E !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #181a20 !important; }

.bg-gray {
  background-color: #686B73 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #505258 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .pgn__searchfield {
  border: 1px solid #b9babe !important; }

.border-top {
  border-top: 1px solid #b9babe !important; }

.border-right {
  border-right: 1px solid #b9babe !important; }

.border-bottom {
  border-bottom: 1px solid #b9babe !important; }

.border-left {
  border-left: 1px solid #b9babe !important; }

.border-0, .pgn__searchfield .form-control {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #23419f !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #178253 !important; }

.border-info {
  border-color: #006DAA !important; }

.border-warning {
  border-color: #D69600 !important; }

.border-danger {
  border-color: #C32D3A !important; }

.border-light {
  border-color: #eee !important; }

.border-dark {
  border-color: #2D323E !important; }

.border-gray {
  border-color: #686B73 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .pgn__searchfield {
  border-radius: 0.3rem !important; }

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important; }

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-lg {
  border-radius: 0.35rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2) !important; }

.shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3) !important; }

.shadow-lg {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.pgn__searchfield .btn[type="submit"],
.pgn__searchfield .btn[type="reset"] {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.pgn__searchfield .btn[type="submit"],
.pgn__searchfield .btn[type="reset"] {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #23419f !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #152760 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #178253 !important; }

a.text-success:hover, a.text-success:focus {
  color: #0c412a !important; }

.text-info {
  color: #006DAA !important; }

a.text-info:hover, a.text-info:focus {
  color: #003c5e !important; }

.text-warning {
  color: #D69600 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #8a6000 !important; }

.text-danger {
  color: #C32D3A !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #851f28 !important; }

.text-light {
  color: #eee !important; }

a.text-light:hover, a.text-light:focus {
  color: #c8c8c8 !important; }

.text-dark {
  color: #2D323E !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0e12 !important; }

.text-gray {
  color: #686B73 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #44464b !important; }

.text-body {
  color: #171C29 !important; }

.text-muted {
  color: #686B73 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #686B73;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #A2A4A9 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #b9babe; }
  .table .thead-dark th {
    color: inherit;
    border-color: #b9babe; } }

.form-control.is-invalid.is-invalid-nodanger {
  border-color: #b9babe; }

.form-inline label {
  margin-right: 5px; }

.collapsible-card .collapsible-trigger, .collapsible-card-lg .collapsible-trigger {
  padding: 0.45rem 0.75rem;
  border-radius: calc(0.3rem - 1px);
  border-bottom: 1px solid transparent;
  transition: border-color 100ms ease 150ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .collapsible-card .collapsible-trigger > *, .collapsible-card-lg .collapsible-trigger > * {
    margin-bottom: 0;
    margin-top: 0; }
  .collapsible-card .collapsible-trigger[aria-expanded="true"], .collapsible-card-lg .collapsible-trigger[aria-expanded="true"] {
    border-radius: calc(0.3rem - 1px) calc(0.3rem - 1px) 0 0;
    border-color: rgba(0, 0, 0, 0.125);
    transition: none; }

.collapsible-card .collapsible-body, .collapsible-card-lg .collapsible-body {
  padding: 0.45rem 0.75rem; }
  .collapsible-card .collapsible-body > *:last-child, .collapsible-card-lg .collapsible-body > *:last-child {
    margin-bottom: 0; }

.collapsible-card-lg .collapsible-trigger {
  padding: 0.75rem 1.25rem; }

.collapsible-card-lg .collapsible-body {
  padding: 0.75rem 1.25rem; }

.collapsible-basic .collapsible-trigger {
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: underline;
  color: #23419f; }

.paragon-fieldset {
  margin-bottom: 1.5rem; }
  .paragon-fieldset .form-control {
    height: auto; }
  .paragon-fieldset fieldset legend {
    width: auto;
    margin-bottom: 0; }

.form-control.is-invalid.is-invalid-nodanger {
  border-color: #b9babe; }
  .form-control.is-invalid.is-invalid-nodanger:focus {
    color: #2D323E;
    background-color: #fff;
    border-color: #6582dd;
    outline: 0;
    box-shadow: 0 0 0 1px #6c83cd; }

.modal.show {
  position: fixed;
  background-color: transparent;
  max-height: 100%;
  width: 100%; }
  .modal.show:focus .modal-dialog {
    box-shadow: 0 0 0 1px #6c83cd; }

.modal.is-ie11 {
  overflow-y: scroll;
  height: auto; }
  .modal.is-ie11 .modal-content {
    height: auto;
    max-height: none; }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 1; }

.modal-dialog {
  height: 100%;
  margin: auto;
  padding: 0.5rem; }
  @media (min-width: 576px) {
    .modal-dialog {
      padding: 1rem; } }

.modal-content {
  max-height: 100%; }

.modal-header {
  flex: 0 0 auto; }

.modal-body {
  overflow: auto; }

.modal-footer {
  flex: 0 0 auto; }

.page-link {
  border-color: #b9babe !important;
  border-radius: 0 !important; }
  .page-link div {
    display: inline-block; }

.page-item > .btn {
  transition: none; }

.pgn__searchfield {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .pgn__searchfield.has-focus {
    box-shadow: 0 0 0 1px #6c83cd; }
  .pgn__searchfield .form-control:focus {
    box-shadow: none; }
  .pgn__searchfield .form-control::-ms-clear, .pgn__searchfield .form-control::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  .pgn__searchfield .form-control::-webkit-search-decoration, .pgn__searchfield .form-control::-webkit-search-cancel-button, .pgn__searchfield .form-control::-webkit-search-results-button, .pgn__searchfield .form-control::-webkit-search-results-decoration {
    display: none; }
  .pgn__searchfield label.has-label-text {
    padding-left: 0.75rem; }

.pgn__stateful-btn-icon {
  margin-right: .5em; }

.btn-header {
  padding: 0;
  font-weight: 700; }

.table th,
.table td {
  word-wrap: break-word; }

.pgn-transition-replace-group {
  transition: height 300ms ease; }

.pgn__transition-replace-enter {
  opacity: 0; }

.pgn__transition-replace-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 300ms ease; }

.pgn__transition-replace-exit {
  opacity: 1; }

.pgn__transition-replace-exit-active {
  opacity: 0;
  transition: opacity 300ms ease; }

.form-control.is-invalid ~ .invalid-feedback-nodanger {
  color: #171C29; }

.fa-icon-spacing {
  padding: 0px 5px 0px 0px; }

.nav-tabs .nav-link {
  background: transparent; }

.bg-primary-100 {
  background-color: #f4f7ff !important; }

a.bg-primary-100:hover, a.bg-primary-100:focus,
button.bg-primary-100:hover,
button.bg-primary-100:focus {
  background-color: #c1d2ff !important; }

.text-primary-100 {
  color: #f4f7ff !important; }

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #a8bfff !important; }

.border-primary-100 {
  border-color: #f4f7ff !important; }

.bg-primary-200 {
  background-color: #D8EDF8 !important; }

a.bg-primary-200:hover, a.bg-primary-200:focus,
button.bg-primary-200:hover,
button.bg-primary-200:focus {
  background-color: #add9f0 !important; }

.text-primary-200 {
  color: #D8EDF8 !important; }

a.text-primary-200:hover, a.text-primary-200:focus {
  color: #97cfec !important; }

.border-primary-200 {
  border-color: #D8EDF8 !important; }

.bg-primary-300 {
  background-color: #6c83cd !important; }

a.bg-primary-300:hover, a.bg-primary-300:focus,
button.bg-primary-300:hover,
button.bg-primary-300:focus {
  background-color: #4663c0 !important; }

.text-primary-300 {
  color: #6c83cd !important; }

a.text-primary-300:hover, a.text-primary-300:focus {
  color: #3c58b0 !important; }

.border-primary-300 {
  border-color: #6c83cd !important; }

.bg-primary-500 {
  background-color: #23419f !important; }

a.bg-primary-500:hover, a.bg-primary-500:focus,
button.bg-primary-500:hover,
button.bg-primary-500:focus {
  background-color: #1a3075 !important; }

.text-primary-500 {
  color: #23419f !important; }

a.text-primary-500:hover, a.text-primary-500:focus {
  color: #152760 !important; }

.border-primary-500 {
  border-color: #23419f !important; }

.bg-primary-700 {
  background-color: #1a337b !important; }

a.bg-primary-700:hover, a.bg-primary-700:focus,
button.bg-primary-700:hover,
button.bg-primary-700:focus {
  background-color: #112251 !important; }

.text-primary-700 {
  color: #1a337b !important; }

a.text-primary-700:hover, a.text-primary-700:focus {
  color: #0d193c !important; }

.border-primary-700 {
  border-color: #1a337b !important; }

.bg-primary-900 {
  background-color: #03195c !important; }

a.bg-primary-900:hover, a.bg-primary-900:focus,
button.bg-primary-900:hover,
button.bg-primary-900:focus {
  background-color: #010c2b !important; }

.text-primary-900 {
  color: #03195c !important; }

a.text-primary-900:hover, a.text-primary-900:focus {
  color: #010512 !important; }

.border-primary-900 {
  border-color: #03195c !important; }

.bg-secondary-100 {
  background-color: #f6f7f7 !important; }

a.bg-secondary-100:hover, a.bg-secondary-100:focus,
button.bg-secondary-100:hover,
button.bg-secondary-100:focus {
  background-color: #dbdfdf !important; }

.text-secondary-100 {
  color: #f6f7f7 !important; }

a.text-secondary-100:hover, a.text-secondary-100:focus {
  color: #ced3d3 !important; }

.border-secondary-100 {
  border-color: #f6f7f7 !important; }

.bg-secondary-200 {
  background-color: #dadddf !important; }

a.bg-secondary-200:hover, a.bg-secondary-200:focus,
button.bg-secondary-200:hover,
button.bg-secondary-200:focus {
  background-color: #bfc4c7 !important; }

.text-secondary-200 {
  color: #dadddf !important; }

a.text-secondary-200:hover, a.text-secondary-200:focus {
  color: #b1b7bc !important; }

.border-secondary-200 {
  border-color: #dadddf !important; }

.bg-secondary-300 {
  background-color: #b6babe !important; }

a.bg-secondary-300:hover, a.bg-secondary-300:focus,
button.bg-secondary-300:hover,
button.bg-secondary-300:focus {
  background-color: #9ba1a6 !important; }

.text-secondary-300 {
  color: #b6babe !important; }

a.text-secondary-300:hover, a.text-secondary-300:focus {
  color: #8e949a !important; }

.border-secondary-300 {
  border-color: #b6babe !important; }

.bg-secondary-500 {
  background-color: #6c757d !important; }

a.bg-secondary-500:hover, a.bg-secondary-500:focus,
button.bg-secondary-500:hover,
button.bg-secondary-500:focus {
  background-color: #545b62 !important; }

.text-secondary-500 {
  color: #6c757d !important; }

a.text-secondary-500:hover, a.text-secondary-500:focus {
  color: #494f54 !important; }

.border-secondary-500 {
  border-color: #6c757d !important; }

.bg-secondary-700 {
  background-color: #565e64 !important; }

a.bg-secondary-700:hover, a.bg-secondary-700:focus,
button.bg-secondary-700:hover,
button.bg-secondary-700:focus {
  background-color: #3e4449 !important; }

.text-secondary-700 {
  color: #565e64 !important; }

a.text-secondary-700:hover, a.text-secondary-700:focus {
  color: #33373b !important; }

.border-secondary-700 {
  border-color: #565e64 !important; }

.bg-secondary-900 {
  background-color: #4c5258 !important; }

a.bg-secondary-900:hover, a.bg-secondary-900:focus,
button.bg-secondary-900:hover,
button.bg-secondary-900:focus {
  background-color: #34393d !important; }

.text-secondary-900 {
  color: #4c5258 !important; }

a.text-secondary-900:hover, a.text-secondary-900:focus {
  color: #292c2f !important; }

.border-secondary-900 {
  border-color: #4c5258 !important; }

.bg-success-100 {
  background-color: #F2FAF7 !important; }

a.bg-success-100:hover, a.bg-success-100:focus,
button.bg-success-100:hover,
button.bg-success-100:focus {
  background-color: #cdece0 !important; }

.text-success-100 {
  color: #F2FAF7 !important; }

a.text-success-100:hover, a.text-success-100:focus {
  color: #bbe5d5 !important; }

.border-success-100 {
  border-color: #F2FAF7 !important; }

.bg-success-200 {
  background-color: #BBE6D7 !important; }

a.bg-success-200:hover, a.bg-success-200:focus,
button.bg-success-200:hover,
button.bg-success-200:focus {
  background-color: #96d8c1 !important; }

.text-success-200 {
  color: #BBE6D7 !important; }

a.text-success-200:hover, a.text-success-200:focus {
  color: #83d1b6 !important; }

.border-success-200 {
  border-color: #BBE6D7 !important; }

.bg-success-300 {
  background-color: #30A171 !important; }

a.bg-success-300:hover, a.bg-success-300:focus,
button.bg-success-300:hover,
button.bg-success-300:focus {
  background-color: #247a55 !important; }

.text-success-300 {
  color: #30A171 !important; }

a.text-success-300:hover, a.text-success-300:focus {
  color: #1e6648 !important; }

.border-success-300 {
  border-color: #30A171 !important; }

.bg-success-500 {
  background-color: #178253 !important; }

a.bg-success-500:hover, a.bg-success-500:focus,
button.bg-success-500:hover,
button.bg-success-500:focus {
  background-color: #0f5737 !important; }

.text-success-500 {
  color: #178253 !important; }

a.text-success-500:hover, a.text-success-500:focus {
  color: #0c412a !important; }

.border-success-500 {
  border-color: #178253 !important; }

.bg-success-700 {
  background-color: #175B3C !important; }

a.bg-success-700:hover, a.bg-success-700:focus,
button.bg-success-700:hover,
button.bg-success-700:focus {
  background-color: #0d3221 !important; }

.text-success-700 {
  color: #175B3C !important; }

a.text-success-700:hover, a.text-success-700:focus {
  color: #081e14 !important; }

.border-success-700 {
  border-color: #175B3C !important; }

.bg-success-900 {
  background-color: #0F4D0F !important; }

a.bg-success-900:hover, a.bg-success-900:focus,
button.bg-success-900:hover,
button.bg-success-900:focus {
  background-color: #072207 !important; }

.text-success-900 {
  color: #0F4D0F !important; }

a.text-success-900:hover, a.text-success-900:focus {
  color: #030d03 !important; }

.border-success-900 {
  border-color: #0F4D0F !important; }

.bg-info-100 {
  background-color: #EFF8FA !important; }

a.bg-info-100:hover, a.bg-info-100:focus,
button.bg-info-100:hover,
button.bg-info-100:focus {
  background-color: #c8e7ee !important; }

.text-info-100 {
  color: #EFF8FA !important; }

a.text-info-100:hover, a.text-info-100:focus {
  color: #b5dfe8 !important; }

.border-info-100 {
  border-color: #EFF8FA !important; }

.bg-info-200 {
  background-color: #9CD2E6 !important; }

a.bg-info-200:hover, a.bg-info-200:focus,
button.bg-info-200:hover,
button.bg-info-200:focus {
  background-color: #73bfdc !important; }

.text-info-200 {
  color: #9CD2E6 !important; }

a.text-info-200:hover, a.text-info-200:focus {
  color: #5fb6d7 !important; }

.border-info-200 {
  border-color: #9CD2E6 !important; }

.bg-info-300 {
  background-color: #1C8DBE !important; }

a.bg-info-300:hover, a.bg-info-300:focus,
button.bg-info-300:hover,
button.bg-info-300:focus {
  background-color: #156c92 !important; }

.text-info-300 {
  color: #1C8DBE !important; }

a.text-info-300:hover, a.text-info-300:focus {
  color: #125c7b !important; }

.border-info-300 {
  border-color: #1C8DBE !important; }

.bg-info-500 {
  background-color: #006DAA !important; }

a.bg-info-500:hover, a.bg-info-500:focus,
button.bg-info-500:hover,
button.bg-info-500:focus {
  background-color: #004c77 !important; }

.text-info-500 {
  color: #006DAA !important; }

a.text-info-500:hover, a.text-info-500:focus {
  color: #003c5e !important; }

.border-info-500 {
  border-color: #006DAA !important; }

.bg-info-700 {
  background-color: #004972 !important; }

a.bg-info-700:hover, a.bg-info-700:focus,
button.bg-info-700:hover,
button.bg-info-700:focus {
  background-color: #00283f !important; }

.text-info-700 {
  color: #004972 !important; }

a.text-info-700:hover, a.text-info-700:focus {
  color: #001826 !important; }

.border-info-700 {
  border-color: #004972 !important; }

.bg-info-900 {
  background-color: #002F4A !important; }

a.bg-info-900:hover, a.bg-info-900:focus,
button.bg-info-900:hover,
button.bg-info-900:focus {
  background-color: #000f17 !important; }

.text-info-900 {
  color: #002F4A !important; }

a.text-info-900:hover, a.text-info-900:focus {
  color: black !important; }

.border-info-900 {
  border-color: #002F4A !important; }

.bg-warning-100 {
  background-color: #fffaed !important; }

a.bg-warning-100:hover, a.bg-warning-100:focus,
button.bg-warning-100:hover,
button.bg-warning-100:focus {
  background-color: #ffecba !important; }

.text-warning-100 {
  color: #fffaed !important; }

a.text-warning-100:hover, a.text-warning-100:focus {
  color: #ffe5a1 !important; }

.border-warning-100 {
  border-color: #fffaed !important; }

.bg-warning-200 {
  background-color: #FFD875 !important; }

a.bg-warning-200:hover, a.bg-warning-200:focus,
button.bg-warning-200:hover,
button.bg-warning-200:focus {
  background-color: #ffca42 !important; }

.text-warning-200 {
  color: #FFD875 !important; }

a.text-warning-200:hover, a.text-warning-200:focus {
  color: #ffc229 !important; }

.border-warning-200 {
  border-color: #FFD875 !important; }

.bg-warning-300 {
  background-color: #FFBF18 !important; }

a.bg-warning-300:hover, a.bg-warning-300:focus,
button.bg-warning-300:hover,
button.bg-warning-300:focus {
  background-color: #e4a500 !important; }

.text-warning-300 {
  color: #FFBF18 !important; }

a.text-warning-300:hover, a.text-warning-300:focus {
  color: #cb9200 !important; }

.border-warning-300 {
  border-color: #FFBF18 !important; }

.bg-warning-500 {
  background-color: #D69600 !important; }

a.bg-warning-500:hover, a.bg-warning-500:focus,
button.bg-warning-500:hover,
button.bg-warning-500:focus {
  background-color: #a37200 !important; }

.text-warning-500 {
  color: #D69600 !important; }

a.text-warning-500:hover, a.text-warning-500:focus {
  color: #8a6000 !important; }

.border-warning-500 {
  border-color: #D69600 !important; }

.bg-warning-700 {
  background-color: #996B00 !important; }

a.bg-warning-700:hover, a.bg-warning-700:focus,
button.bg-warning-700:hover,
button.bg-warning-700:focus {
  background-color: #664700 !important; }

.text-warning-700 {
  color: #996B00 !important; }

a.text-warning-700:hover, a.text-warning-700:focus {
  color: #4d3600 !important; }

.border-warning-700 {
  border-color: #996B00 !important; }

.bg-warning-900 {
  background-color: #7A5500 !important; }

a.bg-warning-900:hover, a.bg-warning-900:focus,
button.bg-warning-900:hover,
button.bg-warning-900:focus {
  background-color: #473100 !important; }

.text-warning-900 {
  color: #7A5500 !important; }

a.text-warning-900:hover, a.text-warning-900:focus {
  color: #2e2000 !important; }

.border-warning-900 {
  border-color: #7A5500 !important; }

.bg-danger-100 {
  background-color: #FCF1F4 !important; }

a.bg-danger-100:hover, a.bg-danger-100:focus,
button.bg-danger-100:hover,
button.bg-danger-100:focus {
  background-color: #f3c7d3 !important; }

.text-danger-100 {
  color: #FCF1F4 !important; }

a.text-danger-100:hover, a.text-danger-100:focus {
  color: #efb2c3 !important; }

.border-danger-100 {
  border-color: #FCF1F4 !important; }

.bg-danger-200 {
  background-color: #F3A9BE !important; }

a.bg-danger-200:hover, a.bg-danger-200:focus,
button.bg-danger-200:hover,
button.bg-danger-200:focus {
  background-color: #ed7c9c !important; }

.text-danger-200 {
  color: #F3A9BE !important; }

a.text-danger-200:hover, a.text-danger-200:focus {
  color: #ea668b !important; }

.border-danger-200 {
  border-color: #F3A9BE !important; }

.bg-danger-300 {
  background-color: #E4406E !important; }

a.bg-danger-300:hover, a.bg-danger-300:focus,
button.bg-danger-300:hover,
button.bg-danger-300:focus {
  background-color: #d31e51 !important; }

.text-danger-300 {
  color: #E4406E !important; }

a.text-danger-300:hover, a.text-danger-300:focus {
  color: #bd1b48 !important; }

.border-danger-300 {
  border-color: #E4406E !important; }

.bg-danger-500 {
  background-color: #C32D3A !important; }

a.bg-danger-500:hover, a.bg-danger-500:focus,
button.bg-danger-500:hover,
button.bg-danger-500:focus {
  background-color: #9a232e !important; }

.text-danger-500 {
  color: #C32D3A !important; }

a.text-danger-500:hover, a.text-danger-500:focus {
  color: #851f28 !important; }

.border-danger-500 {
  border-color: #C32D3A !important; }

.bg-danger-700 {
  background-color: #942947 !important; }

a.bg-danger-700:hover, a.bg-danger-700:focus,
button.bg-danger-700:hover,
button.bg-danger-700:focus {
  background-color: #6c1e34 !important; }

.text-danger-700 {
  color: #942947 !important; }

a.text-danger-700:hover, a.text-danger-700:focus {
  color: #58182a !important; }

.border-danger-700 {
  border-color: #942947 !important; }

.bg-danger-900 {
  background-color: #691B30 !important; }

a.bg-danger-900:hover, a.bg-danger-900:focus,
button.bg-danger-900:hover,
button.bg-danger-900:focus {
  background-color: #40111d !important; }

.text-danger-900 {
  color: #691B30 !important; }

a.text-danger-900:hover, a.text-danger-900:focus {
  color: #2c0b14 !important; }

.border-danger-900 {
  border-color: #691B30 !important; }

.bg-light-100 {
  background-color: #fefefe !important; }

a.bg-light-100:hover, a.bg-light-100:focus,
button.bg-light-100:hover,
button.bg-light-100:focus {
  background-color: #e5e5e5 !important; }

.text-light-100 {
  color: #fefefe !important; }

a.text-light-100:hover, a.text-light-100:focus {
  color: #d8d8d8 !important; }

.border-light-100 {
  border-color: #fefefe !important; }

.bg-light-200 {
  background-color: #fbfbfb !important; }

a.bg-light-200:hover, a.bg-light-200:focus,
button.bg-light-200:hover,
button.bg-light-200:focus {
  background-color: #e2e2e2 !important; }

.text-light-200 {
  color: #fbfbfb !important; }

a.text-light-200:hover, a.text-light-200:focus {
  color: #d5d5d5 !important; }

.border-light-200 {
  border-color: #fbfbfb !important; }

.bg-light-300 {
  background-color: #f7f7f7 !important; }

a.bg-light-300:hover, a.bg-light-300:focus,
button.bg-light-300:hover,
button.bg-light-300:focus {
  background-color: #dedede !important; }

.text-light-300 {
  color: #f7f7f7 !important; }

a.text-light-300:hover, a.text-light-300:focus {
  color: #d1d1d1 !important; }

.border-light-300 {
  border-color: #f7f7f7 !important; }

.bg-light-500 {
  background-color: #eeeeee !important; }

a.bg-light-500:hover, a.bg-light-500:focus,
button.bg-light-500:hover,
button.bg-light-500:focus {
  background-color: #d5d5d5 !important; }

.text-light-500 {
  color: #eeeeee !important; }

a.text-light-500:hover, a.text-light-500:focus {
  color: #c8c8c8 !important; }

.border-light-500 {
  border-color: #eeeeee !important; }

.bg-light-700 {
  background-color: #bebebe !important; }

a.bg-light-700:hover, a.bg-light-700:focus,
button.bg-light-700:hover,
button.bg-light-700:focus {
  background-color: #a5a5a5 !important; }

.text-light-700 {
  color: #bebebe !important; }

a.text-light-700:hover, a.text-light-700:focus {
  color: #989898 !important; }

.border-light-700 {
  border-color: #bebebe !important; }

.bg-light-900 {
  background-color: #a7a7a7 !important; }

a.bg-light-900:hover, a.bg-light-900:focus,
button.bg-light-900:hover,
button.bg-light-900:focus {
  background-color: #8e8e8e !important; }

.text-light-900 {
  color: #a7a7a7 !important; }

a.text-light-900:hover, a.text-light-900:focus {
  color: #818181 !important; }

.border-light-900 {
  border-color: #a7a7a7 !important; }

.bg-dark-100 {
  background-color: #f2f3f3 !important; }

a.bg-dark-100:hover, a.bg-dark-100:focus,
button.bg-dark-100:hover,
button.bg-dark-100:focus {
  background-color: #d7dbdb !important; }

.text-dark-100 {
  color: #f2f3f3 !important; }

a.text-dark-100:hover, a.text-dark-100:focus {
  color: #cacece !important; }

.border-dark-100 {
  border-color: #f2f3f3 !important; }

.bg-dark-200 {
  background-color: #cbcccf !important; }

a.bg-dark-200:hover, a.bg-dark-200:focus,
button.bg-dark-200:hover,
button.bg-dark-200:focus {
  background-color: #b0b2b7 !important; }

.text-dark-200 {
  color: #cbcccf !important; }

a.text-dark-200:hover, a.text-dark-200:focus {
  color: #a3a5aa !important; }

.border-dark-200 {
  border-color: #cbcccf !important; }

.bg-dark-300 {
  background-color: #96999f !important; }

a.bg-dark-300:hover, a.bg-dark-300:focus,
button.bg-dark-300:hover,
button.bg-dark-300:focus {
  background-color: #7b7f87 !important; }

.text-dark-300 {
  color: #96999f !important; }

a.text-dark-300:hover, a.text-dark-300:focus {
  color: #6f7379 !important; }

.border-dark-300 {
  border-color: #96999f !important; }

.bg-dark-500 {
  background-color: #2d323e !important; }

a.bg-dark-500:hover, a.bg-dark-500:focus,
button.bg-dark-500:hover,
button.bg-dark-500:focus {
  background-color: #181a20 !important; }

.text-dark-500 {
  color: #2d323e !important; }

a.text-dark-500:hover, a.text-dark-500:focus {
  color: #0d0e12 !important; }

.border-dark-500 {
  border-color: #2d323e !important; }

.bg-dark-700 {
  background-color: #242832 !important; }

a.bg-dark-700:hover, a.bg-dark-700:focus,
button.bg-dark-700:hover,
button.bg-dark-700:focus {
  background-color: #0f1014 !important; }

.text-dark-700 {
  color: #242832 !important; }

a.text-dark-700:hover, a.text-dark-700:focus {
  color: #040406 !important; }

.border-dark-700 {
  border-color: #242832 !important; }

.bg-dark-900 {
  background-color: #20232b !important; }

a.bg-dark-900:hover, a.bg-dark-900:focus,
button.bg-dark-900:hover,
button.bg-dark-900:focus {
  background-color: #0a0b0e !important; }

.text-dark-900 {
  color: #20232b !important; }

a.text-dark-900:hover, a.text-dark-900:focus {
  color: black !important; }

.border-dark-900 {
  border-color: #20232b !important; }

.bg-gray-100 {
  background-color: #F9F9F9 !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #e0e0e0 !important; }

.text-gray-100 {
  color: #F9F9F9 !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: lightgray !important; }

.border-gray-100 {
  border-color: #F9F9F9 !important; }

.bg-gray-200 {
  background-color: #b9babe !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #9fa0a5 !important; }

.text-gray-200 {
  color: #b9babe !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #919399 !important; }

.border-gray-200 {
  border-color: #b9babe !important; }

.bg-gray-300 {
  background-color: #A2A4A9 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #888a90 !important; }

.text-gray-300 {
  color: #A2A4A9 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #7a7d84 !important; }

.border-gray-300 {
  border-color: #A2A4A9 !important; }

.bg-gray-500 {
  background-color: #686B73 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #505258 !important; }

.text-gray-500 {
  color: #686B73 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #44464b !important; }

.border-gray-500 {
  border-color: #686B73 !important; }

.bg-gray-700 {
  background-color: #2D323E !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #181a20 !important; }

.text-gray-700 {
  color: #2D323E !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #0d0e12 !important; }

.border-gray-700 {
  border-color: #2D323E !important; }

.bg-gray-900 {
  background-color: #171C29 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #050608 !important; }

.text-gray-900 {
  color: #171C29 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.border-gray-900 {
  border-color: #171C29 !important; }

.alert-dismissible .close {
  color: #686B73;
  opacity: 1; }
  .alert-dismissible .close:hover {
    color: #2D323E; }

.badge {
  @badge-pill; }

.btn-link {
  text-decoration: none; }
  .btn-link.hover, .btn-link:hover {
    text-decoration: underline; }
  .btn-link.focus, .btn-link:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
    text-decoration: underline; }

.dropdown-menu {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3); }

.dropdown-item {
  text-decoration: none; }

.valid-feedback {
  color: #171C29;
  font-weight: 400; }
  .valid-feedback:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='2 2 20 20'%3e%3cpath fill='%2330A171' d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M17.5208153,8.03553391 L10.4497475,15.1066017 L6.91421356,11.5710678 L5.5,12.9852814 L10.4497475,17.9350288 L18.9350288,9.44974747 L17.5208153,8.03553391 Z'/%3e%3c/svg%3e");
    content: '';
    height: 1rem;
    width: 1rem;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: .3em; }

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #30A171;
  box-shadow: 0 0 0 1px #30A171; }

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #30A171;
  box-shadow: 0 0 0 1px #30A171; }

.invalid-feedback {
  color: #171C29;
  font-weight: 400; }
  .invalid-feedback:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E4406E' viewBox='2 2 20 20'%3e%3cpath d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M13.25,15.5 L10.75,15.5 C10.6119288,15.5 10.5,15.6119288 10.5,15.75 L10.5,15.75 L10.5,18.25 C10.5,18.3880712 10.6119288,18.5 10.75,18.5 L10.75,18.5 L13.25,18.5 C13.3880712,18.5 13.5,18.3880712 13.5,18.25 L13.5,18.25 L13.5,15.75 C13.5,15.6119288 13.3880712,15.5 13.25,15.5 L13.25,15.5 Z M13.492539,5.5 L10.5001113,5.50010806 C10.3620998,5.50416722 10.2535099,5.61933826 10.2575691,5.75734976 L10.2575691,5.75734976 L10.4928632,13.7573498 C10.4968382,13.8925005 10.607546,14 10.7427552,14 L10.7427552,14 L13.2572448,14 C13.392454,14 13.5031618,13.8925005 13.5071368,13.7573498 L13.5071368,13.7573498 L13.7424309,5.75734976 L13.7424309,5.75734976 C13.742539,5.61192881 13.6306101,5.5 13.492539,5.5 L13.492539,5.5 Z' /%3e%3c/svg%3E");
    content: '';
    height: 1rem;
    width: 1rem;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: .3em; }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #E4406E;
  box-shadow: 0 0 0 1px #E4406E; }

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #E4406E;
  box-shadow: 0 0 0 1px #E4406E; }

.container {
  padding-left: 30px;
  padding-right: 30px; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px; }

.modal-content {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
  border: solid 1px #d6dbe0; }

.modal-footer {
  justify-content: flex-start;
  padding-bottom: 1.75rem;
  padding-top: 0.75rem; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: "Georgia", serif;
  font-weight: 700;
  line-height: 1.111111111111111; }

h1, .h1 {
  font-family: "Georgia", serif;
  font-weight: 700; }

h2,
h3,
h4,
.h2,
.h3,
.h4 {
  font-weight: 500; }

h5,
h6,
.h5,
.h6 {
  font-weight: 700; }

label {
  line-height: 1.25;
  font-weight: 700; }

small,
.small {
  line-height: 1.428571428571429; }

@media (max-width: 576px) {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  h1, .h1 {
    font-size: 2rem;
    line-height: 1.25; }
  h2, .h2 {
    font-size: 1.75rem; }
  h3, .h3 {
    font-size: 1.5rem; }
  h4, .h4 {
    font-size: 1.375rem; } }

.nav-link.active {
  color: #171C29;
  text-decoration: none;
  font-weight: 500; }

.edx-custom.card, .edx-custom.collapsible-card, .edx-custom.collapsible-card-lg {
  border-top: 1px solid #d6dbe0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-radius: unset; }
  .edx-custom.card .card-content, .edx-custom.collapsible-card .card-content, .edx-custom.collapsible-card-lg .card-content {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif; }
  .edx-custom.card .card-title, .edx-custom.collapsible-card .card-title, .edx-custom.collapsible-card-lg .card-title {
    text-decoration: underline; }
  .edx-custom.card .card-body, .edx-custom.collapsible-card .card-body, .edx-custom.collapsible-card-lg .card-body, .edx-custom.card .collapsible-card .collapsible-body, .collapsible-card .edx-custom.card .collapsible-body, .edx-custom.collapsible-card .collapsible-body, .edx-custom.card .collapsible-card-lg .collapsible-body, .collapsible-card-lg .edx-custom.card .collapsible-body, .edx-custom.collapsible-card-lg .collapsible-body {
    padding: 15px 20px; }
    .edx-custom.card .card-body .card-title, .edx-custom.collapsible-card .card-body .card-title, .edx-custom.collapsible-card-lg .card-body .card-title, .edx-custom.card .collapsible-card .collapsible-body .card-title, .collapsible-card .edx-custom.card .collapsible-body .card-title, .edx-custom.collapsible-card .collapsible-body .card-title, .edx-custom.card .collapsible-card-lg .collapsible-body .card-title, .collapsible-card-lg .edx-custom.card .collapsible-body .card-title, .edx-custom.collapsible-card-lg .collapsible-body .card-title {
      text-decoration: underline;
      margin: 0 0 5px;
      font-size: 1.25rem;
      font-weight: 400;
      padding-bottom: 10px;
      line-height: 1.5; }
    .edx-custom.card .card-body .card-partner, .edx-custom.collapsible-card .card-body .card-partner, .edx-custom.collapsible-card-lg .card-body .card-partner, .edx-custom.card .collapsible-card .collapsible-body .card-partner, .collapsible-card .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card .collapsible-body .card-partner, .edx-custom.card .collapsible-card-lg .collapsible-body .card-partner, .collapsible-card-lg .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card-lg .collapsible-body .card-partner {
      font-size: 1rem; }
      @media (min-width: 992px) {
        .edx-custom.card .card-body .card-partner, .edx-custom.collapsible-card .card-body .card-partner, .edx-custom.collapsible-card-lg .card-body .card-partner, .edx-custom.card .collapsible-card .collapsible-body .card-partner, .collapsible-card .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card .collapsible-body .card-partner, .edx-custom.card .collapsible-card-lg .collapsible-body .card-partner, .collapsible-card-lg .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card-lg .collapsible-body .card-partner {
          font-size: 0.875rem; } }
      @media (min-width: 1200px) {
        .edx-custom.card .card-body .card-partner, .edx-custom.collapsible-card .card-body .card-partner, .edx-custom.collapsible-card-lg .card-body .card-partner, .edx-custom.card .collapsible-card .collapsible-body .card-partner, .collapsible-card .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card .collapsible-body .card-partner, .edx-custom.card .collapsible-card-lg .collapsible-body .card-partner, .collapsible-card-lg .edx-custom.card .collapsible-body .card-partner, .edx-custom.collapsible-card-lg .collapsible-body .card-partner {
          font-size: 1rem; } }
  .edx-custom.card .card-footer, .edx-custom.collapsible-card .card-footer, .edx-custom.collapsible-card-lg .card-footer,
  .edx-custom.card .out-of,
  .edx-custom.collapsible-card .out-of,
  .edx-custom.collapsible-card-lg .out-of,
  .edx-custom.card .type,
  .edx-custom.collapsible-card .type,
  .edx-custom.collapsible-card-lg .type,
  .edx-custom.card .card-overlay,
  .edx-custom.collapsible-card .card-overlay,
  .edx-custom.collapsible-card-lg .card-overlay {
    display: none; }
  .edx-custom.card .card-stats, .edx-custom.collapsible-card .card-stats, .edx-custom.collapsible-card-lg .card-stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-end;
    padding-left: 20px;
    padding-right: 20px;
    color: #393f43;
    font-size: 1rem; }
    .edx-custom.card .card-stats .course-count, .edx-custom.collapsible-card .card-stats .course-count, .edx-custom.collapsible-card-lg .card-stats .course-count {
      margin-right: 20px; }
  @media (min-width: 576px) {
    .edx-custom.card, .edx-custom.collapsible-card, .edx-custom.collapsible-card-lg {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid #d6dbe0;
      border-radius: 4px; }
      .edx-custom.card .card-content, .edx-custom.collapsible-card .card-content, .edx-custom.collapsible-card-lg .card-content {
        height: 190px; }
        .edx-custom.card .card-content:hover, .edx-custom.collapsible-card .card-content:hover, .edx-custom.collapsible-card-lg .card-content:hover, .edx-custom.card .card-content:focus-within, .edx-custom.collapsible-card .card-content:focus-within, .edx-custom.collapsible-card-lg .card-content:focus-within, .edx-custom.card .card-content:active, .edx-custom.collapsible-card .card-content:active, .edx-custom.collapsible-card-lg .card-content:active {
          background-color: #f5f8ff; }
      
      .edx-custom.card .card-overlay,
      .edx-custom.collapsible-card .card-overlay,
      .edx-custom.collapsible-card-lg .card-overlay {
        display: block;
        top: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        border-color: #d6dbe0; }
        .edx-custom.card .card-overlay::after, .edx-custom.collapsible-card .card-overlay::after, .edx-custom.collapsible-card-lg .card-overlay::after {
          content: "";
          width: 28px;
          height: 28px;
          background: #f5f8ff;
          position: absolute;
          top: -14px;
          right: calc(50% - 14px);
          transform: rotate(45deg);
          border-left: 2px solid #d6dbe0;
          border-top: 2px solid #d6dbe0; }
        .edx-custom.card .card-overlay .card-overlay-link, .edx-custom.collapsible-card .card-overlay .card-overlay-link, .edx-custom.collapsible-card-lg .card-overlay .card-overlay-link {
          text-decoration: underline;
          color: #23419f; }
        .edx-custom.card .card-overlay .card-overlay-body, .edx-custom.collapsible-card .card-overlay .card-overlay-body, .edx-custom.collapsible-card-lg .card-overlay .card-overlay-body {
          background: #f5f8ff;
          font-size: 1rem;
          color: #636c72;
          padding: 20px;
          border: 1px solid #d6dbe0;
          border-radius: 4px; }
          .edx-custom.card .card-overlay .card-overlay-body .h5, .edx-custom.collapsible-card .card-overlay .card-overlay-body .h5, .edx-custom.collapsible-card-lg .card-overlay .card-overlay-body .h5 {
            font-size: 1rem;
            font-weight: 700; }
        .edx-custom.card .card-overlay .card-overlay-close, .edx-custom.collapsible-card .card-overlay .card-overlay-close, .edx-custom.collapsible-card-lg .card-overlay .card-overlay-close {
          right: 0%;
          top: 0%;
          background: none; }
      .edx-custom.card .card-body, .edx-custom.collapsible-card .card-body, .edx-custom.collapsible-card-lg .card-body, .edx-custom.card .collapsible-card .collapsible-body, .collapsible-card .edx-custom.card .collapsible-body, .edx-custom.collapsible-card .collapsible-body, .edx-custom.card .collapsible-card-lg .collapsible-body, .collapsible-card-lg .edx-custom.card .collapsible-body, .edx-custom.collapsible-card-lg .collapsible-body {
        height: 125px;
        padding: 20px; }
        .edx-custom.card .card-body .card-title, .edx-custom.collapsible-card .card-body .card-title, .edx-custom.collapsible-card-lg .card-body .card-title, .edx-custom.card .collapsible-card .collapsible-body .card-title, .collapsible-card .edx-custom.card .collapsible-body .card-title, .edx-custom.collapsible-card .collapsible-body .card-title, .edx-custom.card .collapsible-card-lg .collapsible-body .card-title, .collapsible-card-lg .edx-custom.card .collapsible-body .card-title, .edx-custom.collapsible-card-lg .collapsible-body .card-title {
          padding-bottom: 0; }
        .edx-custom.card .card-body .h6, .edx-custom.collapsible-card .card-body .h6, .edx-custom.collapsible-card-lg .card-body .h6, .edx-custom.card .collapsible-card .collapsible-body .h6, .collapsible-card .edx-custom.card .collapsible-body .h6, .edx-custom.collapsible-card .collapsible-body .h6, .edx-custom.card .collapsible-card-lg .collapsible-body .h6, .collapsible-card-lg .edx-custom.card .collapsible-body .h6, .edx-custom.collapsible-card-lg .collapsible-body .h6 {
          font-size: 1rem; }
        .edx-custom.card .card-body .out-of, .edx-custom.collapsible-card .card-body .out-of, .edx-custom.collapsible-card-lg .card-body .out-of, .edx-custom.card .collapsible-card .collapsible-body .out-of, .collapsible-card .edx-custom.card .collapsible-body .out-of, .edx-custom.collapsible-card .collapsible-body .out-of, .edx-custom.card .collapsible-card-lg .collapsible-body .out-of, .collapsible-card-lg .edx-custom.card .collapsible-body .out-of, .edx-custom.collapsible-card-lg .collapsible-body .out-of {
          display: block;
          color: #fff;
          position: relative;
          z-index: 0;
          border-radius: 0 4px 0 0;
          overflow: hidden;
          min-height: 65px;
          min-width: 65px;
          margin-top: -20px;
          margin-right: -20px;
          padding-top: 12px;
          padding-left: 34px;
          font-size: 0.875rem; }
          .edx-custom.card .card-body .out-of::before, .edx-custom.collapsible-card .card-body .out-of::before, .edx-custom.collapsible-card-lg .card-body .out-of::before, .edx-custom.card .collapsible-card .collapsible-body .out-of::before, .collapsible-card .edx-custom.card .collapsible-body .out-of::before, .edx-custom.collapsible-card .collapsible-body .out-of::before, .edx-custom.card .collapsible-card-lg .collapsible-body .out-of::before, .collapsible-card-lg .edx-custom.card .collapsible-body .out-of::before, .edx-custom.collapsible-card-lg .collapsible-body .out-of::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            border: 32px solid #17285d;
            border-bottom-color: transparent;
            border-left-color: transparent; }
        .edx-custom.card .card-body.program, .edx-custom.collapsible-card .card-body.program, .edx-custom.collapsible-card-lg .card-body.program, .edx-custom.card .collapsible-card .program.collapsible-body, .collapsible-card .edx-custom.card .program.collapsible-body, .edx-custom.collapsible-card .program.collapsible-body, .edx-custom.card .collapsible-card-lg .program.collapsible-body, .collapsible-card-lg .edx-custom.card .program.collapsible-body, .edx-custom.collapsible-card-lg .program.collapsible-body {
          background-color: #17285d;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
          color: #fff;
          box-sizing: border-box; }
          .edx-custom.card .card-body.program .card-partner, .edx-custom.collapsible-card .card-body.program .card-partner, .edx-custom.collapsible-card-lg .card-body.program .card-partner, .edx-custom.card .collapsible-card .program.collapsible-body .card-partner, .collapsible-card .edx-custom.card .program.collapsible-body .card-partner, .edx-custom.collapsible-card .program.collapsible-body .card-partner, .edx-custom.card .collapsible-card-lg .program.collapsible-body .card-partner, .collapsible-card-lg .edx-custom.card .program.collapsible-body .card-partner, .edx-custom.collapsible-card-lg .program.collapsible-body .card-partner,
          .edx-custom.card .card-body.program .card-title,
          .edx-custom.collapsible-card .card-body.program .card-title,
          .edx-custom.collapsible-card-lg .card-body.program .card-title,
          .edx-custom.card .collapsible-card .program.collapsible-body .card-title,
          .collapsible-card .edx-custom.card .program.collapsible-body .card-title,
          .edx-custom.collapsible-card .program.collapsible-body .card-title,
          .edx-custom.card .collapsible-card-lg .program.collapsible-body .card-title,
          .collapsible-card-lg .edx-custom.card .program.collapsible-body .card-title,
          .edx-custom.collapsible-card-lg .program.collapsible-body .card-title {
            color: #fff; }
          .edx-custom.card .card-body.program .card-link:focus, .edx-custom.collapsible-card .card-body.program .card-link:focus, .edx-custom.collapsible-card-lg .card-body.program .card-link:focus, .edx-custom.card .collapsible-card .program.collapsible-body .card-link:focus, .collapsible-card .edx-custom.card .program.collapsible-body .card-link:focus, .edx-custom.collapsible-card .program.collapsible-body .card-link:focus, .edx-custom.card .collapsible-card-lg .program.collapsible-body .card-link:focus, .collapsible-card-lg .edx-custom.card .program.collapsible-body .card-link:focus, .edx-custom.collapsible-card-lg .program.collapsible-body .card-link:focus {
            outline: none; }
            .edx-custom.card .card-body.program .card-link:focus .card-title, .edx-custom.collapsible-card .card-body.program .card-link:focus .card-title, .edx-custom.collapsible-card-lg .card-body.program .card-link:focus .card-title, .edx-custom.card .collapsible-card .program.collapsible-body .card-link:focus .card-title, .collapsible-card .edx-custom.card .program.collapsible-body .card-link:focus .card-title, .edx-custom.collapsible-card .program.collapsible-body .card-link:focus .card-title, .edx-custom.card .collapsible-card-lg .program.collapsible-body .card-link:focus .card-title, .collapsible-card-lg .edx-custom.card .program.collapsible-body .card-link:focus .card-title, .edx-custom.collapsible-card-lg .program.collapsible-body .card-link:focus .card-title {
              outline: 1px dotted #fff; }
          .edx-custom.card .card-body.program .type, .edx-custom.collapsible-card .card-body.program .type, .edx-custom.collapsible-card-lg .card-body.program .type, .edx-custom.card .collapsible-card .program.collapsible-body .type, .collapsible-card .edx-custom.card .program.collapsible-body .type, .edx-custom.collapsible-card .program.collapsible-body .type, .edx-custom.card .collapsible-card-lg .program.collapsible-body .type, .collapsible-card-lg .edx-custom.card .program.collapsible-body .type, .edx-custom.collapsible-card-lg .program.collapsible-body .type {
            display: block;
            font-size: 0.875rem;
            font-weight: 700;
            margin-top: -12px;
            margin-right: -12px; }
      .edx-custom.card .card-footer, .edx-custom.collapsible-card .card-footer, .edx-custom.collapsible-card-lg .card-footer {
        display: block;
        color: #23419f;
        width: 100%;
        border-top: 1px solid #d6dbe0;
        box-sizing: border-box;
        background-color: #fff;
        height: 41px; }
        .edx-custom.card .card-footer.btn, .edx-custom.collapsible-card .card-footer.btn, .edx-custom.collapsible-card-lg .card-footer.btn {
          padding: 10px 20px;
          background-color: #fff;
          color: #23419f;
          justify-content: space-between;
          box-sizing: border-box; }
        .edx-custom.card .card-footer:hover, .edx-custom.collapsible-card .card-footer:hover, .edx-custom.collapsible-card-lg .card-footer:hover, .edx-custom.card .card-footer.focus-within, .edx-custom.collapsible-card .card-footer.focus-within, .edx-custom.collapsible-card-lg .card-footer.focus-within, .edx-custom.card .card-footer.active, .edx-custom.collapsible-card .card-footer.active, .edx-custom.collapsible-card-lg .card-footer.active {
          background-color: #f5f8ff; }
        .edx-custom.card .card-footer.expanded-true, .edx-custom.collapsible-card .card-footer.expanded-true, .edx-custom.collapsible-card-lg .card-footer.expanded-true {
          background-color: #f5f8ff;
          font-weight: 700; }
        .edx-custom.card .card-footer .label-wrapper, .edx-custom.collapsible-card .card-footer .label-wrapper, .edx-custom.collapsible-card-lg .card-footer .label-wrapper {
          display: flex;
          justify-content: space-between; }
      .edx-custom.card .card-stats, .edx-custom.collapsible-card .card-stats, .edx-custom.collapsible-card-lg .card-stats {
        padding: 10px 20px;
        height: 60px; } }

.max-width-222 {
  max-width: 222px; }

.max-width-264 {
  max-width: 264px; }

.max-width-1180 {
  max-width: 1180px; }

.max-height-39 {
  max-height: 39px; }

.d-grid {
  display: grid; }

.footer {
  background-color: #fcfcfc; }
  .footer .area-1 {
    grid-column: 1;
    grid-row: 1;
    border-bottom: 1px solid #b9babe;
    padding-bottom: 1rem; }
  .footer .area-2 {
    grid-column: 1;
    grid-row: 2;
    border-bottom: 1px solid #b9babe;
    padding: 1rem 0; }
  .footer .area-3 {
    grid-column: 1;
    grid-row: 3;
    border-bottom: 1px solid #b9babe;
    padding: 1rem 0; }
  .footer .area-4 {
    grid-column: 1;
    grid-row: 4;
    border-bottom: 1px solid #b9babe;
    padding: 1rem 0; }
  .footer .area-5 {
    grid-column: 1;
    grid-row: 5;
    padding: 1rem 0; }
  @media only screen and (min-width: 717px) {
    .footer .area-1 {
      grid-column: 1 / span 2;
      grid-row: 1;
      border-bottom: none;
      padding: 1rem 0; }
    .footer .area-2 {
      grid-column: 1;
      grid-row: 2; }
    .footer .area-3 {
      grid-column: 1;
      grid-row: 3; }
    .footer .area-4 {
      grid-column: 1;
      grid-row: 4;
      border-bottom: none; }
    .footer .area-5 {
      grid-column: 2;
      grid-row: 2 / span 3;
      border-left: 1px solid #b9babe;
      padding-left: 1rem;
      margin-left: 1rem; } }
  @media only screen and (min-width: 870px) {
    .footer .area-1 {
      grid-column: 1;
      grid-row: 1 / span 3;
      border-right: 1px solid #b9babe;
      padding-right: 1rem;
      margin-right: 1rem; }
    .footer .area-2 {
      grid-column: 2;
      grid-row: 1;
      border-bottom: none;
      border-right: 1px solid #b9babe;
      padding-right: 1rem;
      margin-right: 1rem; }
    .footer .area-3 {
      grid-column: 3;
      grid-row: 1;
      border-bottom: none;
      border-right: 1px solid #b9babe;
      padding-right: 1rem;
      margin-right: 1rem; }
    .footer .area-4 {
      grid-column: 4;
      grid-row: 1; }
    .footer .area-5 {
      grid-column: 2 / span 3;
      grid-row: 2;
      border: none;
      margin-left: 0;
      padding-left: 0; } }
  @media only screen and (min-width: 1188px) {
    .footer .area-1 {
      grid-column: 1 / span 1;
      grid-row: 1; }
    .footer .area-2 {
      grid-column: 2;
      grid-row: 1; }
    .footer .area-3 {
      grid-column: 3;
      grid-row: 1; }
    .footer .area-4 {
      grid-column: 4;
      grid-row: 1;
      border-right: 1px solid #b9babe;
      padding-right: 1rem;
      margin-right: 1rem; }
    .footer .area-5 {
      grid-column: 5 / span 1;
      grid-row: 1;
      max-width: 372px; } }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Light-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Light-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-LightItalic-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-LightItalic-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-LightItalic-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Regular-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Regular-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Italic-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Italic-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Italic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Semibold-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Semibold-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Bold-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Bold-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff2") format("woff2"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff") format("woff"), url("~edx-pattern-library/pattern-library/fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

.content-supplementary .bit-publishing .wrapper-last-draft .user {
  word-wrap: break-word; }

.form-create .list-input {
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0; }
  .form-create .list-input li {
    margin: 0;
    padding: 0; }

.form-create {
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border: 1px solid #b2b2b2;
  background: #fff; }
  .form-create::after {
    display: block;
    clear: both;
    content: ""; }
  .form-create:hover, .form-create:active {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }

.content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  line-height: 1.5em;
  text-align: center; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish:active, .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish:focus {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2); }
  .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish {
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.4); }
    .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish:active, .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish:focus, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish:active, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish:focus {
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.4); }

.form-create .list-input .field.error .is-showing {
  animation: fadeIn 0.25s linear 1; }

.form-create .list-input .field.error .is-hiding {
  animation: fadeOut 0.25s linear 1; }

.library-list-wrapper .library-list .library-item .library-link {
  z-index: 100; }

.wrapper-header {
  z-index: 1000; }

.library-list-wrapper .library-list .library-item .library-metadata .metadata-item .label {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  margin: -1px;
  height: 1px;
  width: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  word-wrap: normal; }

.content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
  box-sizing: border-box;
  transition: color 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s, background 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s;
  display: inline-block;
  cursor: pointer; }
  @media (prefers-reduced-motion: reduce) {
    .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
      transition: none; } }
  .content-supplementary .bit-publishing .wrapper-pub-actions .disabled.action-publish, .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish[disabled], .content-supplementary .bit-publishing .wrapper-pub-actions .is-disabled.action-publish {
    cursor: default;
    pointer-events: none;
    border: 1px solid #cccccc;
    background-color: #cccccc;
    color: #f2f2f2; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish .icon-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }

.content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
  border-radius: 4px; }

.page-header .navigation,
.page-header .subtitle {
  font-weight: 400; }

.form-create .list-input .field input,
.form-create .list-input .field textarea, body, .content, .form-create .list-input .field label, .content header .title-sub, .content-supplementary .bit, .content-supplementary .bit-publishing .bar-mod-content, .content-supplementary .bit-publishing .wrapper-pub-actions .action-discard, .library-list-wrapper .library-list .library-item .library-metadata, .form-create .list-input .field .text-muted, .form-create .list-input .field .invalid-feedback, .content-primary header .tip, .content-supplementary .bit-publishing .bar-mod-content .meta {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.form-create .list-input .field input,
.form-create .list-input .field textarea, body, .content {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 23.68px;
  line-height: 2.368rem; }

.form-create .list-input .field label, .content header .title-sub, .content-supplementary .bit, .content-supplementary .bit-publishing .bar-mod-content, .content-supplementary .bit-publishing .wrapper-pub-actions .action-discard, .library-list-wrapper .library-list .library-item .library-metadata {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20.72px;
  line-height: 2.072rem; }

.form-create .list-input .field .text-muted, .form-create .list-input .field .invalid-feedback, .content-primary header .tip, .content-supplementary .bit-publishing .bar-mod-content .meta {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 17.76px;
  line-height: 1.776rem; }

.add-xblock-component .new-component-template .button-component {
  font-weight: 500; }

.form-create .title, .wrapper-mast .mast.has-actions .page-header .page-header-title, .content-primary .title-2, .library-list-wrapper .library-list .library-item .library-title, .add-xblock-component .new-component h5, .content-primary .title-3, .content-supplementary .bit-publishing .bar-mod-title, .page-header .navigation,
.page-header .subtitle, .content-supplementary .bit h3, .content-supplementary .bit-publishing .wrapper-release .release-date, .content-supplementary .bit-publishing .wrapper-visibility .copy, .content-supplementary .bit-publishing .bar-mod-content .title, .content-supplementary .bit-publishing .wrapper-release .release-with, .content-supplementary .bit-publishing .wrapper-visibility .inherited-from, .content-supplementary .bit-publishing .wrapper-visibility .note-visibility {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.page-header, .content header .title-1, .content-primary .title-1 {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 53.28px;
  line-height: 5.328rem; }

.form-create .title, .wrapper-mast .mast.has-actions .page-header .page-header-title, .content-primary .title-2, .library-list-wrapper .library-list .library-item .library-title {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 35.52px;
  line-height: 3.552rem; }

.add-xblock-component .new-component h5 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 26.64px;
  line-height: 2.664rem; }

.content-primary .title-3, .content-supplementary .bit-publishing .bar-mod-title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 23.68px;
  line-height: 2.368rem; }

.page-header .navigation,
.page-header .subtitle, .content-supplementary .bit h3, .content-supplementary .bit-publishing .wrapper-release .release-date, .content-supplementary .bit-publishing .wrapper-visibility .copy {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20.72px;
  line-height: 2.072rem; }

.content-supplementary .bit-publishing .bar-mod-content .title, .content-supplementary .bit-publishing .wrapper-release .release-with, .content-supplementary .bit-publishing .wrapper-visibility .inherited-from, .content-supplementary .bit-publishing .wrapper-visibility .note-visibility {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 17.76px;
  line-height: 1.776rem; }

.wrapper-header .info-library .library-title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 23.68px;
  line-height: 2.368rem; }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button, .add-xblock-component .new-component-templates .cancel-button, .add-xblock-component .problem-type-tabs a, .wrapper-header nav > ol > .nav-item {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20.72px;
  line-height: 2.072rem; }

.wrapper-header .info-library .library-org,
.wrapper-header .info-library .library-id {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 17.76px;
  line-height: 1.776rem; }

.content-supplementary .bit-publishing .wrapper-pub-actions .action-publish, .form-create .title, .form-create .list-input .field.required label, .form-create .actions .action, .page-header, .wrapper-mast .mast.has-actions .page-header .page-header-title, .content-supplementary .bit h3, .add-xblock-component .new-component h5, .add-xblock-component .new-component-templates .cancel-button, .content-supplementary .bit-publishing .bar-mod-title, .content-supplementary .bit-publishing .bar-mod-content .title, .content-supplementary .bit-publishing .wrapper-release .release-date, .content-supplementary .bit-publishing .wrapper-visibility .copy, .content-supplementary .bit-publishing .wrapper-last-draft .date, .content-supplementary .bit-publishing .wrapper-last-draft .user, .wrapper-header nav > ol > .nav-item, .wrapper-header .info-library .library-title {
  font-weight: 600; }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button {
  font-weight: 500; }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button {
  box-sizing: border-box;
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  display: inline-block;
  cursor: pointer;
  text-decoration: none; }
  @media (prefers-reduced-motion: reduce) {
    .add-xblock-component .new-component .new-component-type .add-xblock-component-button {
      transition: none; } }
  .add-xblock-component .new-component .new-component-type .add-xblock-component-button:hover, .add-xblock-component .new-component .new-component-type .add-xblock-component-button:active, .add-xblock-component .new-component .new-component-type .add-xblock-component-button:focus {
    text-decoration: none; }
  .add-xblock-component .new-component .new-component-type .disabled.add-xblock-component-button, .add-xblock-component .new-component .new-component-type .add-xblock-component-button[disabled] {
    cursor: default;
    pointer-events: none; }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button {
  border-radius: 2px; }

.content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
  background: #23419f;
  border-color: #23419f;
  color: gray; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish:active {
    background: #065683;
    border-color: #065683; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish {
    background: #065683;
    border-color: #065683;
    color: #d3d9ec; }
    .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .current.action-publish:active, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish:hover, .content-supplementary .bit-publishing .wrapper-pub-actions .active.action-publish:active {
      background: #065683; }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button {
  border: none;
  padding: 15px 20px;
  text-align: center;
  text-shadow: none; }
  .add-xblock-component .new-component .new-component-type .disabled.add-xblock-component-button, .add-xblock-component .new-component .new-component-type .is-disabled.add-xblock-component-button, .add-xblock-component .new-component .new-component-type .add-xblock-component-button[disabled] {
    background: #a4a6a8;
    color: rgba(255, 255, 255, 0.75); }

.add-xblock-component .new-component .new-component-type .add-xblock-component-button {
  box-shadow: 0 2px 1px 0 #004d00;
  background: #006700;
  color: #fff; }
  .add-xblock-component .new-component .new-component-type .add-xblock-component-button:hover, .add-xblock-component .new-component .new-component-type .add-xblock-component-button:active, .add-xblock-component .new-component .new-component-type .add-xblock-component-button:focus {
    background: #009b00;
    color: #fff; }
  .add-xblock-component .new-component .new-component-type .current.add-xblock-component-button, .add-xblock-component .new-component .new-component-type .active.add-xblock-component-button {
    box-shadow: inset 0 2px 1px 1px #008100;
    background: #66b366;
    color: #008100; }
    .add-xblock-component .new-component .new-component-type .current.add-xblock-component-button:hover, .add-xblock-component .new-component .new-component-type .current.add-xblock-component-button:active, .add-xblock-component .new-component .new-component-type .current.add-xblock-component-button:focus, .add-xblock-component .new-component .new-component-type .active.add-xblock-component-button:hover, .add-xblock-component .new-component .new-component-type .active.add-xblock-component-button:active, .add-xblock-component .new-component .new-component-type .active.add-xblock-component-button:focus {
      box-shadow: inset 0 2px 1px 1px #006700;
      color: #006700; }
  .add-xblock-component .new-component .new-component-type .disabled.add-xblock-component-button, .add-xblock-component .new-component .new-component-type .add-xblock-component-button[disabled] {
    box-shadow: none; }

.form-create .title {
  padding: 20px 30px 0 30px; }

.form-create fieldset {
  padding: 20px 30px; }

.form-create .list-input .field {
  margin: 0 0 15px 0; }
  .form-create .list-input .field:last-child {
    margin-bottom: 0; }
  .form-create .list-input .field.required label::after {
    margin-left: 5px;
    content: "*"; }
  .form-create .list-input .field label,
  .form-create .list-input .field input,
  .form-create .list-input .field textarea {
    display: block; }
  .form-create .list-input .field label {
    transition: color 0.125s ease-in-out 0s;
    margin: 0 0 5px 0; }
    @media (prefers-reduced-motion: reduce) {
      .form-create .list-input .field label {
        transition: none; } }
    .form-create .list-input .field label.is-focused {
      color: #23419f; }
  .form-create .list-input .field input,
  .form-create .list-input .field textarea {
    transition: all 0.25s ease-in-out 0s;
    height: 100%;
    width: 100%;
    padding: 10px; }
    @media (prefers-reduced-motion: reduce) {
      .form-create .list-input .field input,
      .form-create .list-input .field textarea {
        transition: none; } }
    .form-create .list-input .field input.long,
    .form-create .list-input .field textarea.long {
      width: 100%; }
    .form-create .list-input .field input.short,
    .form-create .list-input .field textarea.short {
      width: 25%; }
    .form-create .list-input .field input:focus + .text-muted,
    .form-create .list-input .field textarea:focus + .text-muted {
      color: #666666; }
  .form-create .list-input .field textarea.long {
    height: 100px; }
  .form-create .list-input .field input[type="checkbox"] {
    display: inline-block;
    margin-right: 5px;
    width: auto;
    height: auto; }
    .form-create .list-input .field input[type="checkbox"] + label {
      display: inline-block; }
  .form-create .list-input .field .text-muted, .form-create .list-input .field .invalid-feedback {
    transition: color 0.15s ease-in-out;
    display: block;
    margin-top: 5px;
    color: #666666; }
    @media (prefers-reduced-motion: reduce) {
      .form-create .list-input .field .text-muted, .form-create .list-input .field .invalid-feedback {
        transition: none; } }
  .form-create .list-input .field .tip-note {
    display: block;
    margin-top: 5px; }
  .form-create .list-input .field .tip-error {
    display: none;
    float: none; }
  .form-create .list-input .field .invalid-feedback {
    color: #C32D3A; }
    .form-create .list-input .field .invalid-feedback::before {
      vertical-align: unset; }
  .form-create .list-input .field.error label {
    color: #C32D3A; }
  .form-create .list-input .field.error .tip-error {
    display: block;
    color: #C32D3A; }
  .form-create .list-input .field.error input {
    border-color: #C32D3A; }

.form-create .list-input .field-inline input,
.form-create .list-input .field-inline textarea,
.form-create .list-input .field-inline select {
  width: 62%;
  display: inline-block; }

.form-create .list-input .field-inline .tip-stacked {
  display: inline-block;
  float: right;
  width: 35%;
  margin-top: 0; }

.form-create .list-input .field-group {
  margin: 0 0 10px 0; }
  .form-create .list-input .field-group::after {
    display: block;
    clear: both;
    content: ""; }
  .form-create .list-input .field-group .field {
    display: block;
    width: 47%;
    border-bottom: none;
    margin: 0 15px 0 0;
    padding: 5px 0 0 0;
    float: left;
    position: relative; }
    .form-create .list-input .field-group .field:nth-child(odd) {
      float: left; }
    .form-create .list-input .field-group .field:nth-child(even) {
      float: right;
      margin-right: 0; }
    .form-create .list-input .field-group .field input,
    .form-create .list-input .field-group .field textarea {
      width: 100%; }

.form-create .actions {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  border-top: 1px solid #999999;
  padding: 15px 30px;
  background: #f9f9f9; }
  .form-create .actions .action {
    transition: all 0.25s linear 0s;
    display: inline-block;
    padding: 4px 20px;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-right: 0.5rem; }
    @media (prefers-reduced-motion: reduce) {
      .form-create .actions .action {
        transition: none; } }

html {
  font-size: 62.5%;
  height: 102%; }

body {
  min-width: 900px;
  background: whitesmoke;
  color: #333333; }

body,
input,
button {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.page-header {
  display: block;
  color: #333333; }
  .page-header .navigation,
  .page-header .subtitle {
    position: relative;
    top: 5px;
    display: block;
    color: #4c4c4c; }

.wrapper {
  box-sizing: border-box;
  width: 100%; }
  .wrapper::after {
    display: block;
    clear: both;
    content: ""; }

.wrapper-mast {
  margin: 30px 0 0 0;
  padding: 0;
  position: relative; }
  .wrapper-mast .mast,
  .wrapper-mast .metadata {
    position: relative;
    max-width: 1280px;
    min-width: 900px;
    width: 100%;
    margin: 0 auto 20px auto;
    color: #4c4c4c; }
    .wrapper-mast .mast::after,
    .wrapper-mast .metadata::after {
      display: block;
      clear: both;
      content: ""; }
  .wrapper-mast .mast {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px; }
    .wrapper-mast .mast .page-header {
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      width: 100%;
      font-size: 3.6rem;
      line-height: 5.328rem;
      font-weight: 600;
      color: #333333; }
    .wrapper-mast .mast.has-actions::after {
      display: block;
      clear: both;
      content: ""; }
    .wrapper-mast .mast.has-actions .page-header {
      float: left;
      width: 48.93617%;
      margin-right: 2.12766%; }
      .wrapper-mast .mast.has-actions .page-header .page-header-title {
        margin: 12px 0; }
    .wrapper-mast .mast.has-actions .nav-actions {
      position: relative;
      float: right;
      width: 48.93617%;
      text-align: right; }
      .wrapper-mast .mast.has-actions .nav-actions .nav-item {
        display: inline-block;
        vertical-align: top;
        line-height: 1.776rem;
        margin-right: 10px; }
        .wrapper-mast .mast.has-actions .nav-actions .nav-item button {
          font-size: 1.2rem;
          font-weight: 600; }
          .wrapper-mast .mast.has-actions .nav-actions .nav-item button svg {
            width: 2rem; }
        .wrapper-mast .mast.has-actions .nav-actions .nav-item:last-child {
          margin-right: 0; }

.wrapper-content {
  margin: 0;
  padding: 0;
  position: relative; }

.content {
  display: flex;
  max-width: 1280px;
  min-width: 900px;
  width: 100%;
  margin: 0 auto;
  color: #4c4c4c; }
  .content::after {
    display: block;
    clear: both;
    content: ""; }
  .content header {
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px; }
    .content header .title-sub {
      display: block;
      margin: 0;
      color: #b2b2b2; }
    .content header .title-1 {
      margin: 0;
      padding: 0;
      font-weight: 600;
      color: #333333; }

.content-primary,
.content-supplementary {
  box-sizing: border-box; }

.content-primary {
  flex: 0 0 75%;
  width: 75%;
  margin-right: 2.12%; }
  .content-primary .title-2 {
    margin: 0 0 10px 0; }
  .content-primary .title-3 {
    margin: 0 0 10px 0; }
  .content-primary header::after {
    display: block;
    clear: both;
    content: ""; }
  .content-primary header .title-2 {
    width: 40.42553%;
    margin: 0 2.12766% 0 0;
    float: left; }
  .content-primary header .tip {
    text-align: right;
    float: right;
    width: 57.44681%;
    margin-top: 10px;
    color: #666666; }

.content-supplementary {
  flex: 0 0 25%;
  max-width: 25%; }
  .content-supplementary .bit {
    margin: 0 0 20px 0;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 20px 0;
    color: #666666; }
    .content-supplementary .bit:last-child {
      margin-bottom: 0;
      border: none;
      padding-bottom: 0; }
    .content-supplementary .bit h3 {
      margin: 0 0 5px 0;
      color: #4c4c4c; }
    .content-supplementary .bit p {
      line-height: 20.7167px; }
    .content-supplementary .bit .list-actions {
      list-style: none;
      padding: 0; }
      .content-supplementary .bit .list-actions a {
        color: #0075b4; }

.add-xblock-component {
  margin: 20px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
  background-color: #f2f2f2;
  padding: 10px; }
  .add-xblock-component .new-component {
    text-align: center; }
    .add-xblock-component .new-component h5 {
      margin-bottom: 15px;
      color: #008100; }
    .add-xblock-component .new-component .new-component-type::after {
      display: block;
      clear: both;
      content: ""; }
    .add-xblock-component .new-component .new-component-type li {
      display: inline-block; }
    .add-xblock-component .new-component .new-component-type .add-xblock-component-button {
      margin-right: 15px;
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(255, 255, 255, 0.4) inset;
      border-radius: 5px;
      padding: 0;
      text-align: center; }
      .add-xblock-component .new-component .new-component-type .add-xblock-component-button .name {
        box-sizing: border-box;
        display: block;
        color: #fff; }
  .add-xblock-component .new-component-templates {
    display: none;
    margin: 20px 40px;
    border-radius: 3px;
    border: 1px solid #b0b6c2;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(255, 255, 255, 0.4) inset; }
    .add-xblock-component .new-component-templates::after {
      display: block;
      clear: both;
      content: ""; }
    .add-xblock-component .new-component-templates .cancel-button {
      transition: background-color 0.15s, box-shadow 0.15s;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 0 rgba(0, 0, 0, 0);
      display: inline-block;
      padding: 4px 20px 5px;
      background-color: rgba(255, 255, 255, 0.6);
      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
      /* Removed due to issues with the CSS optimizer. */
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset;
      border: 1px solid #b0b6c2;
      border-radius: 3px;
      background-color: #dfe5eb;
      color: #5c677a;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
      margin: 20px 0 10px 10px; }
      @media (prefers-reduced-motion: reduce) {
        .add-xblock-component .new-component-templates .cancel-button {
          transition: none; } }
      .add-xblock-component .new-component-templates .cancel-button.disabled, .add-xblock-component .new-component-templates .cancel-button.is-disabled {
        border: 1px solid #999999 !important;
        border-radius: 3px !important;
        background: #999999 !important;
        color: #666666 !important;
        pointer-events: none;
        cursor: none; }
        .add-xblock-component .new-component-templates .cancel-button.disabled:hover, .add-xblock-component .new-component-templates .cancel-button.disabled:focus, .add-xblock-component .new-component-templates .cancel-button.is-disabled:hover, .add-xblock-component .new-component-templates .cancel-button.is-disabled:focus {
          box-shadow: 0 0 0 0 !important; }
      .add-xblock-component .new-component-templates .cancel-button:hover, .add-xblock-component .new-component-templates .cancel-button:focus, .add-xblock-component .new-component-templates .cancel-button:active {
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 1px 1px rgba(0, 0, 0, 0.15); }
      .add-xblock-component .new-component-templates .cancel-button:hover, .add-xblock-component .new-component-templates .cancel-button:focus {
        background-color: #deecf7;
        color: #5c677a; }
    .add-xblock-component .new-component-templates .problem-type-tabs {
      display: none; }
    .add-xblock-component .new-component-templates.new-component-problem .problem-type-tabs {
      display: inline-block; }
    .add-xblock-component .new-component-templates .support-documentation {
      float: right;
      margin: 20px 0 10px 10px;
      font-size: 14px;
      font-size: 1.4rem; }
      .add-xblock-component .new-component-templates .support-documentation .support-documentation-level {
        padding-right: 10px; }
      .add-xblock-component .new-component-templates .support-documentation .support-documentation-link {
        color: #23419f;
        text-decoration: none;
        padding-right: 10px; }
        .add-xblock-component .new-component-templates .support-documentation .support-documentation-link:hover {
          color: #065683;
          text-decoration: underline; }
    .add-xblock-component .new-component-templates .support-level {
      padding-right: 10px; }
    .add-xblock-component .new-component-templates .icon {
      color: #0ea6ec; }
  .add-xblock-component .new-component-template {
    margin-bottom: 0; }
    .add-xblock-component .new-component-template::after {
      display: block;
      clear: both;
      content: ""; }
    .add-xblock-component .new-component-template li {
      border: none;
      border-bottom: 1px dashed #edf1f5; }
      .add-xblock-component .new-component-template li:first-child {
        border-radius: 3px 3px 0 0; }
    .add-xblock-component .new-component-template .button-component {
      transition: none;
      display: block;
      width: 100%;
      border: 0;
      padding: 7px 20px;
      background: #fff;
      color: #333333;
      text-align: left;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
      .add-xblock-component .new-component-template .button-component::after {
        display: block;
        clear: both;
        content: ""; }
      .add-xblock-component .new-component-template .button-component:hover {
        transition: background-color 0.25s linear 0s;
        background: #5da887;
        color: #fff; }
        @media (prefers-reduced-motion: reduce) {
          .add-xblock-component .new-component-template .button-component:hover {
            transition: none; } }
        .add-xblock-component .new-component-template .button-component:hover .icon {
          color: #fff; }
  .add-xblock-component .problem-type-tabs {
    background-color: rgba(255, 255, 255, 0.4);
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    /* Removed due to issues with the CSS optimizer. */
    list-style-type: none;
    width: 100%;
    border-radius: 0;
    background-color: #c5cfdf;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 -1px 0 rgba(0, 0, 0, 0.2) inset; }
    .add-xblock-component .problem-type-tabs li:first-child {
      margin-left: 20px; }
    .add-xblock-component .problem-type-tabs li {
      background-color: rgba(255, 255, 255, 0.4);
      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
      /* Removed due to issues with the CSS optimizer. */
      opacity: 0.8;
      float: left;
      display: inline-block;
      width: auto;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 -1px 0 rgba(0, 0, 0, 0.2) inset;
      background-color: #cbd4e2;
      text-align: center; }
      .add-xblock-component .problem-type-tabs li:hover {
        opacity: 0.9;
        background-color: #d1d9e5; }
      .add-xblock-component .problem-type-tabs li.ui-state-active {
        background-color: rgba(255, 255, 255, 0.4);
        background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
        /* Removed due to issues with the CSS optimizer. */
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2) inset, 0 1px 0 #fff inset;
        background-color: rgba(255, 255, 255, 0.3);
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        border: 0;
        opacity: 1; }
      .add-xblock-component .problem-type-tabs li a.link-tab {
        float: none; }
    .add-xblock-component .problem-type-tabs a {
      display: block;
      padding: 15px 25px;
      text-align: center;
      color: #333333;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3); }

.content-supplementary .bit-publishing.is-live, .content-supplementary .bit-publishing.is-ready, .content-supplementary .bit-publishing.has-warnings, .content-supplementary .bit-publishing.has-errors, .content-supplementary .bit-publishing.staff-only, .content-supplementary .bit-publishing.is-staff-only, .content-supplementary .bit-publishing.is-gated, .content-supplementary .bit-publishing {
  margin-bottom: 20px;
  border-top: 5px solid #999999;
  background-color: #fff; }
  .content-supplementary .bit-publishing .bar-mod-title {
    display: block;
    padding: 10px 15px;
    background-color: #e5e5e5; }
  .content-supplementary .bit-publishing .bar-mod-content {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 15px 20px 15px; }
    .content-supplementary .bit-publishing .bar-mod-content .title {
      margin-bottom: 5px;
      color: #4c4c4c;
      text-transform: uppercase; }
    .content-supplementary .bit-publishing .bar-mod-content .meta {
      color: #4c4c4c; }

.content-supplementary .bit-publishing.is-live {
  border-top: 5px solid #23419f; }
  .content-supplementary .bit-publishing.is-live .bar-mod-title {
    background-color: rgba(35, 65, 159, 0.125); }

.content-supplementary .bit-publishing.is-ready {
  border-top: 5px solid #178253; }
  .content-supplementary .bit-publishing.is-ready .bar-mod-title {
    background-color: #e8f3ee; }

.content-supplementary .bit-publishing.has-warnings {
  border-top: 5px solid #feb272; }
  .content-supplementary .bit-publishing.has-warnings .bar-mod-title {
    background-color: #fff2e8; }

.content-supplementary .bit-publishing.has-errors {
  border-top: 5px solid #db8189; }
  .content-supplementary .bit-publishing.has-errors .bar-mod-title {
    background-color: #f9eaeb; }

.content-supplementary .bit-publishing.staff-only, .content-supplementary .bit-publishing.is-staff-only, .content-supplementary .bit-publishing.is-gated {
  border-top: 5px solid #000; }
  .content-supplementary .bit-publishing.staff-only .bar-mod-title, .content-supplementary .bit-publishing.is-staff-only .bar-mod-title, .content-supplementary .bit-publishing.is-gated .bar-mod-title {
    background-color: #e5e5e5; }

.content-supplementary .bit-publishing .wrapper-release .release-with, .content-supplementary .bit-publishing .wrapper-visibility .inherited-from, .content-supplementary .bit-publishing .wrapper-visibility .note-visibility {
  display: block; }

.content-supplementary .bit-publishing.staff-only.is-scheduled .wrapper-release .copy, .content-supplementary .bit-publishing.is-staff-only.is-scheduled .wrapper-release .copy {
  text-decoration: line-through; }

.content-supplementary .bit-publishing .bar-mod-content {
  border: 0;
  padding: 10px 15px 5px 15px; }
  .content-supplementary .bit-publishing .bar-mod-content .title {
    margin-bottom: 2px; }

.content-supplementary .bit-publishing .wrapper-visibility .copy {
  margin-bottom: 2px; }

.content-supplementary .bit-publishing .wrapper-visibility .icon {
  color: #666666; }

.content-supplementary .bit-publishing .wrapper-visibility .note-visibility .icon {
  margin-right: 5px; }

.content-supplementary .bit-publishing .wrapper-pub-actions {
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding: 20px 15px 15px 15px; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .action-publish {
    display: block;
    padding: 5px 10px 6.66667px 10px; }
  .content-supplementary .bit-publishing .wrapper-pub-actions .action-discard {
    display: block;
    margin-top: 10px;
    text-align: right; }
    .content-supplementary .bit-publishing .wrapper-pub-actions .action-discard.is-disabled {
      pointer-events: none;
      color: #999999; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-bottom: 30px; }

.footer {
  background-color: transparent; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 1280px;
  padding-left: 0;
  padding-right: 0; }

.library-block-wrapper {
  margin: 0;
  font-size: 1.4rem; }

/* Adapted from:
 * edx-platform/cms/static/sass/views/_dashboard.scss
 */
.library-index-tabs {
  margin: 0;
  padding: 0;
  font-size: 1.4rem; }
  .library-index-tabs li {
    display: inline-block;
    line-height: 40px;
    margin: 0 10px; }
    .library-index-tabs li.active {
      border-bottom: 4px solid #0075b4; }
    .library-index-tabs li.active a, .library-index-tabs li:hover a {
      color: #4c4c4c; }
    .library-index-tabs li a {
      color: #0075b4;
      cursor: pointer;
      display: inline-block; }

.well {
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
  background-color: #f2f2f2;
  padding: 10px; }

.page-header-section h1 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.sidebar-info {
  font-size: 90%;
  line-height: 2rem; }
  .sidebar-info h3 {
    font-size: 100%;
    font-weight: bold; }

/* Adapted from:
 * edx-platform/cms/static/sass/elements/_header.scss
 */
.wrapper-header {
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0 20px;
  background: #fff; }
  .wrapper-header header.primary {
    box-sizing: border-box;
    max-width: 1280px;
    min-width: 900px;
    margin: 0 auto;
    min-height: 79px; }
    .wrapper-header header.primary::after {
      display: block;
      clear: both;
      content: ""; }
  .wrapper-header .wrapper-l,
  .wrapper-header .wrapper-r {
    display: flex;
    background: #fff;
    min-height: 79px; }
  .wrapper-header .wrapper-l {
    float: left;
    width: 74.46809%; }
  .wrapper-header .wrapper-r {
    float: right;
    text-align: right; }
  .wrapper-header .branding,
  .wrapper-header .info-library,
  .wrapper-header .nav-account {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0; }
  .wrapper-header .nav-account {
    position: relative;
    padding: 17px 0;
    width: auto; }
  .wrapper-header nav .dropdown .btn {
    font-size: 1.6rem;
    font-weight: 600;
    background-color: white;
    color: #666666;
    border-width: 1px;
    line-height: 2.368rem; }
    .wrapper-header nav .dropdown .btn:hover {
      color: #065683; }
  .wrapper-header nav .dropdown .dropdown-menu {
    font-size: 1.4rem;
    width: 160px; }
  .wrapper-header nav .dropdown .dropdown-item:active {
    color: #065683;
    background-color: white; }
  .wrapper-header nav > ol {
    margin: 0;
    padding: 0; }
    .wrapper-header nav > ol > .nav-item {
      display: inline-block;
      vertical-align: middle; }
      .wrapper-header nav > ol > .nav-item:last-child {
        margin-right: 0; }
      .wrapper-header nav > ol > .nav-item .title {
        max-width: 210px;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0.375rem 1.25rem;
        margin: 0; }
      .wrapper-header nav > ol > .nav-item a {
        color: #666666;
        text-decoration: none; }
        .wrapper-header nav > ol > .nav-item a:hover {
          color: #065683; }
  .wrapper-header .branding {
    padding: 15px 0;
    margin-right: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .wrapper-header .branding a {
      display: block; }
      .wrapper-header .branding a img {
        display: block;
        max-height: 40px; }
  .wrapper-header .info-library {
    margin-right: 2%;
    border-right: 1px solid #e5e5e5;
    width: 25%;
    padding: 15px 2% 15px 0; }
    .wrapper-header .info-library .library-org,
    .wrapper-header .info-library .library-id {
      display: inline-block;
      vertical-align: middle;
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      opacity: 1;
      color: #333333;
      font-size: 1.2rem;
      line-height: 1.776rem; }
    .wrapper-header .info-library .library-org {
      margin-right: 5px; }
    .wrapper-header .info-library .library-title {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 2.368rem;
      font-size: 1.6rem; }
    .wrapper-header .info-library .library-link {
      transition: color 0.25s ease-in-out 0s;
      display: block;
      color: #333333;
      text-decoration: none; }
      @media (prefers-reduced-motion: reduce) {
        .wrapper-header .info-library .library-link {
          transition: none; } }
      .wrapper-header .info-library .library-link:hover {
        color: #065683; }

.importable-course-list-container {
  margin-bottom: 2em !important; }

/* Adapted from:
 * edx-platform/cms/static/sass/views/_dashboard.scss
 */
.library-list-wrapper {
  margin: 0;
  font-size: 1.4rem; }
  .library-list-wrapper .library-list-pagination {
    display: block;
    margin-top: 1.5em; }
    .library-list-wrapper .library-list-pagination * {
      font-size: 1.4rem; }
    .library-list-wrapper .library-list-pagination .pagination .page-item .btn {
      min-width: 2.5em;
      min-height: 2.5em;
      border: 1px solid #b2b2b2; }
  .library-list-wrapper .library-list {
    border-radius: 3px;
    border: 1px solid #b2b2b2;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0; }
    .library-list-wrapper .library-list li {
      display: inline-block;
      line-height: 40px;
      margin: 0; }
      .library-list-wrapper .library-list li a {
        color: #0075b4;
        cursor: pointer;
        display: inline-block;
        text-decoration: none; }
      .library-list-wrapper .library-list li:hover a {
        color: #065683; }
    .library-list-wrapper .library-list li:last-child {
      margin-bottom: 0; }
    .library-list-wrapper .library-list .library-item.no-hover-bg:hover {
      background: none; }
    .library-list-wrapper .library-list .library-item {
      box-sizing: border-box;
      width: 100%;
      position: relative;
      border-bottom: 1px solid #b2b2b2; }
      .library-list-wrapper .library-list .library-item:hover {
        background: #fffcf1; }
      .library-list-wrapper .library-list .library-item .library-link {
        margin-right: 2%;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle; }
      .library-list-wrapper .library-list .library-item .library-title {
        margin: 0 40px 5px 0;
        font-weight: 300; }
      .library-list-wrapper .library-list .library-item .library-metadata {
        transition: opacity 0.5s ease-in-out 0;
        color: #4c4c4c; }
        @media (prefers-reduced-motion: reduce) {
          .library-list-wrapper .library-list .library-item .library-metadata {
            transition: none; } }
        .library-list-wrapper .library-list .library-item .library-metadata .metadata-item {
          display: inline-block; }
          .library-list-wrapper .library-list .library-item .library-metadata .metadata-item + .metadata-item::before {
            content: "/";
            margin-left: 5px;
            margin-right: 5px;
            color: #e5e5e5; }
      .library-list-wrapper .library-list .library-item.is-processing .course-status .value {
        color: #b2b2b2; }
      .library-list-wrapper .library-list .library-item.has-error .course-status {
        color: #C32D3A; }
      .library-list-wrapper .library-list .library-item.has-error ~ .status-message {
        background: #cf5761;
        color: #fff; }
      .library-list-wrapper .library-list .library-item:last-child {
        border-bottom: none; }
  .library-list-wrapper .filter-form .form-label {
    font-weight: 600; }
  .library-list-wrapper .filter-form button,
  .library-list-wrapper .filter-form .form-control {
    font-size: 1.4rem; }

.library-block-wrapper {
  margin: 0;
  font-size: 1.4rem; }

.library-detail-wrapper .invalid-feedback {
  display: block;
  background-color: #fff;
  font-size: 70%;
  border-radius: 3px; }

.library-detail-wrapper .add-xblock-component .new-component-type .add-xblock-component-button {
  height: auto; }

.cta-button {
  padding: 1rem 2rem;
  font-size: 120%; }

.library-blocks-pagination {
  display: block;
  margin-top: 1.5em; }
  .library-blocks-pagination * {
    font-size: 1.4rem; }
  .library-blocks-pagination .pagination .page-item .btn {
    min-width: 2.5em;
    min-height: 2.5em;
    border: 1px solid #b2b2b2; }

.perm-badge {
  position: absolute;
  top: -1.25rem;
  margin-left: .5rem;
  padding: .25rem .75rem .25rem .75rem;
  color: white;
  font-size: 70%;
  text-transform: uppercase; }
  .perm-badge.admin {
    background-color: #e83e8c; }
  .perm-badge.author {
    background-color: #23419f; }
  .perm-badge.read {
    background-color: #ab7800; }

.permy {
  top: -1rem;
  color: #fff; }
  .permy.admin {
    background-color: #e83e8c; }
  .permy.author {
    background-color: #23419f; }
  .permy.read {
    background-color: #ab7800; }

.card-title {
  font-size: 150%; }

.library-access-wrapper .form-create {
  border: none; }
  .library-access-wrapper .form-create:hover {
    box-shadow: none; }
